import { getEnvVar } from '../utils/env-var.utils';

export interface ApiConfig {
  readonly origin: string;
  readonly originCheckout: string;
}

export interface CognitoConfig {
  readonly clientId: string;
  readonly userPoolId: string;
  readonly gandalfIdpName: string;
  readonly amazonFederateIdpName: string;
  readonly appWebDomain: string;
  readonly gandalfLogin: string;
  readonly gandalfLogout: string;
  readonly gandalfRegion: string;
}

export interface AuthGroupNames {
  readonly generalAccess: string;
  readonly eventAdmin: string;
  readonly challengeAdmin: string;
  readonly campaignAdmin: string;
}

export interface DomainConfig {
  readonly gameUI: string;
}

// billing info config interfaceß
export interface BillingConfig {
  readonly purchaseHistory: string;
}

export interface RumConfig {
  readonly guestRoleArn: string;
  readonly identityPoolId: string;
  readonly applicationId: string;
  readonly applicationRegion: string;
}

export interface AppConfig {
  readonly api: ApiConfig;
  readonly cognito: CognitoConfig;
  readonly domains: DomainConfig;
  readonly warningBannerMessage: string;
  readonly challengeResourcesBucketName: string;
  readonly stageName: string;
  readonly useHybridExperience: boolean;
  readonly isUsagePlanEnabled: boolean;
  readonly isChallengeReviewEnabled: boolean;
  readonly isEventTemplateEnabled: boolean;
  readonly isNewTopNavigationEnabled: boolean;
  readonly isGandalfOtpRedirectEnabled: boolean;
  readonly billing: BillingConfig;
  readonly rum: RumConfig;
  readonly showAdminPages: boolean;
}

const localBackendOrigin = 'http://localhost:8080';

/**
 * Create a new app config by inspecting the environment.
 */
export const newAppConfig = (): AppConfig => {
  const useLocalBackend = process.env.REACT_APP_LOCAL_BACKEND === 'true';
  return {
    api: {
      origin: useLocalBackend ? /* istanbul ignore next */ localBackendOrigin : getEnvVar('REACT_APP_API_ORIGIN'),
      originCheckout: useLocalBackend
        ? /* istanbul ignore next */ localBackendOrigin
        : getEnvVar('REACT_APP_CHECKOUT_GRAPHQL_API_ORIGIN'),
    },
    cognito: {
      clientId: getEnvVar('REACT_APP_GANDALF_COGNITO_CLIENT_ID'),
      userPoolId: getEnvVar('REACT_APP_GANDALF_COGNITO_USER_POOL_ID'),
      gandalfIdpName: getEnvVar('REACT_APP_COGNITO_GANDALF_IDP_NAME'),
      amazonFederateIdpName: getEnvVar('REACT_APP_COGNITO_AMAZON_FEDERATE_IDP_NAME'),
      appWebDomain: getEnvVar('REACT_APP_GANDALF_COGNITO_APP_WEB_DOMAIN'),
      gandalfLogin: getEnvVar('REACT_APP_GANDALF_LOGIN_WEB_DOMAIN'),
      gandalfLogout: getEnvVar('REACT_APP_GANDALF_WEB_DOMAIN'),
      gandalfRegion: getEnvVar('REACT_APP_GANDALF_REGION'),
    },
    domains: {
      gameUI: getEnvVar('REACT_APP_GAME_UI_DOMAIN'),
    },
    warningBannerMessage: getEnvVar('REACT_APP_WARNING_BANNER_MESSAGE', ''),
    challengeResourcesBucketName: getEnvVar('REACT_APP_CHALLENGE_RESOURCES_BUCKET_NAME'),
    stageName: getEnvVar('REACT_APP_STAGE_NAME'),
    useHybridExperience: getEnvVar('REACT_APP_USE_HYBRID_EXPERIENCE') === 'true',
    isUsagePlanEnabled: getEnvVar('REACT_APP_IS_USAGE_PLAN_ENABLED') === 'true',
    isChallengeReviewEnabled: getEnvVar('REACT_APP_IS_CHALLENGE_REVIEW_ENABLED') === 'true',
    isEventTemplateEnabled: getEnvVar('REACT_APP_IS_EVENT_TEMPLATE_ENABLED') === 'true',
    isNewTopNavigationEnabled: getEnvVar('REACT_APP_IS_NEW_TOP_NAVIGATION_ENABLED') === 'true',
    isGandalfOtpRedirectEnabled: getEnvVar('REACT_APP_GANDALF_OTP_REDIRECT_ENABLED') === 'true',
    billing: {
      purchaseHistory: getEnvVar('REACT_APP_BILLING_PURCHASE_HISTORY_URL'),
    },
    rum: {
      guestRoleArn: getEnvVar('REACT_APP_RUM_GUEST_ROLE_ARN'),
      identityPoolId: getEnvVar('REACT_APP_RUM_IDENTITY_POOL_ID'),
      applicationId: getEnvVar('REACT_APP_RUM_APPLICATION_ID'),
      applicationRegion: getEnvVar('REACT_APP_RUM_APPLICATION_REGION'),
    },
    showAdminPages: getEnvVar('REACT_APP_SHOW_ADMIN_PAGES') === 'true',
  };
};
