import React, { useEffect } from 'react';
import { Box, Button } from '@amzn/awsui-components-react';
import { useHistory } from 'react-router-dom';

// TO DO: move all molecules to the global molecules folder once event templates is merged
import CustomSlider from '../molecules/CustomSlider/CustomSlider';

import './HomePage.scss';
import { RoutePath } from '@/src/RoutePath';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useEventTemplate } from '@/src/store/event-template.context';
import MeetAwsJam from '../molecules/MeetAwsJam/MeetAwsJam';
import BookEventSteps from '../molecules/BookEventSteps/BookEventSteps';
import SEO from '@/src/components/common/SeoComponent';
import RealReviews from '../molecules/RealReviews/RealReviews';
import JamSpinner from '@/src/components/common/JamSpinner';
import FooterLinks from '../molecules/FooterLinks/FooterLinks';
import { useUser } from '@/src/store/user.context';
import { customEventTrigger } from '../../analytics/createEventTrigger';

const HomePage = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const history = useHistory();
  const {
    trendingEventTemplates,
    trendingTemplateLoading,
    beginnersEventTemplates,
    beginnersTemplateLoading,
    fetchBeginnersEventTemplate,
    fetchTrendingEventTemplate,
  } = useEventTemplate();
  const eventSliderResponsive = [
    {
      breakpoint: 7000,
      settings: { slidesToShow: 10 },
    },
    {
      breakpoint: 6200,
      settings: { slidesToShow: 9 },
    },
    {
      breakpoint: 5400,
      settings: { slidesToShow: 8 },
    },
    {
      breakpoint: 4500,
      settings: { slidesToShow: 7 },
    },
    {
      breakpoint: 3600,
      settings: { slidesToShow: 6 },
    },
    {
      breakpoint: 2800,
      settings: { slidesToShow: 5 },
    },
    {
      breakpoint: 2000,
      settings: { slidesToShow: 4 },
    },
    {
      breakpoint: 1200,
      settings: { slidesToShow: 3 },
    },
    {
      breakpoint: 896,
      settings: { slidesToShow: 2 },
    },
    {
      breakpoint: 600,
      settings: { slidesToShow: 1 },
    },
  ];

  const redirectToAllEvents = () => {
    customEventTrigger('click', 'Discover Event', window.location.href, 'Discover Event', { element: 'Button' });
    history.push(RoutePath.CATALOG);
  };

  const redirectToJoinEvent = () => {
    customEventTrigger('click', 'Join an event', window.location.href, 'Join an event', { element: 'Button' });
    history.push(RoutePath.JOIN_AN_EVENT);
  };

  useEffect(() => {
    fetchTrendingEventTemplate();
    fetchBeginnersEventTemplate();
  }, []);

  if (trendingTemplateLoading || beginnersTemplateLoading) {
    return (
      <div className="spinner-homepage">
        <JamSpinner />
      </div>
    );
  }

  return (
    <div className="home-page-container">
      <SEO useStatic page="home" description={t(i18nKeys.catalog.banner.awsJamCaption)} />
      <div className="home-cover__outer" style={{ backgroundImage: "url('/assets/homepage-bg.png')" }}>
        <div className="home-cover">
          <div className="text-container">
            <Box fontWeight="light" padding={{ bottom: 'xl' }}>
              <span className="home-cover__category">{t(i18nKeys.catalog.banner.trainingAndCertification)}</span>
            </Box>
            <Box variant="h1" fontWeight="heavy" fontSize="display-l" color="inherit">
              {t(i18nKeys.catalog.banner.awsJam)}
            </Box>
            <Box variant="p" fontWeight="light" padding={{ bottom: 's' }} color="inherit">
              <span className="header-cover__sub-title">{t(i18nKeys.catalog.banner.handsOnLearning)}</span>
            </Box>
            <div className="home-cover__caption">
              <Box fontWeight="light" padding={{ bottom: 'xl' }}>
                <span className="home-cover__header-caption">{t(i18nKeys.catalog.banner.awsJamCaption)}</span>
              </Box>
            </div>

            <Box padding={{ bottom: 'xxl' }}>
              <Box display="inline-block" padding={{ bottom: 'l' }}>
                <Button variant="primary" data-testid="primary-button" onClick={redirectToAllEvents}>
                  {t(i18nKeys.catalog.discoverEvents)}
                </Button>
                <Button variant="normal" data-testid="secondary-button" onClick={redirectToJoinEvent}>
                  {t(i18nKeys.catalog.joinAnEvent)}
                </Button>
              </Box>
            </Box>
          </div>
          {/* <AnimatedIllustration illustrations={trendingEventTemplates} /> */}
        </div>
        <div className="homepage-hero">
          <div className="homepage-hero__image" style={{ backgroundImage: "url('/assets/Homepage-hero.png')" }} />
        </div>
      </div>
      <div className="book-event">
        <BookEventSteps onBrowseAllEvents={redirectToAllEvents} />
      </div>
      <div className="discover-events">
        <div className="discover-events-title">
          <Box variant="h1" fontSize="display-l">
            {t(i18nKeys.catalog.discoverEvents)}
          </Box>
          <Box variant="p">{t(i18nKeys.catalog.discoverEventsCaption)}</Box>
        </div>
        <CustomSlider
          heading={t(i18nKeys.catalog.trendingEvents)}
          items={trendingEventTemplates}
          responsive={eventSliderResponsive}
          viewAllLink={`${RoutePath.CATALOG}`}
          isSubscribed={user?.isSubscribedUser || user?.isSuperAdmin}
        />
        <div className="beginner-event-slide">
          <CustomSlider
            heading={t(i18nKeys.catalog.beginnersEvents)}
            items={beginnersEventTemplates}
            responsive={eventSliderResponsive}
            viewAllLink={`${RoutePath.CATALOG}?tags=Good+For+Beginners`}
            isSubscribed={user?.isSubscribedUser || user?.isSuperAdmin}
          />
        </div>
        <div className="discover-events__browse-all">
          <Button onClick={redirectToAllEvents}>{t(i18nKeys.catalog.browseAllEvents)}</Button>
        </div>
      </div>

      <div className="meet-aws-jam">
        <MeetAwsJam />
      </div>
      <div className="real-reviews-container">
        <RealReviews />
      </div>
      <div className="footer-links-container">
        <FooterLinks />
      </div>
    </div>
  );
};

export default HomePage;
