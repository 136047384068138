import React, { useEffect, useState } from 'react';
import { Box, Table } from '@amzn/awsui-components-react';
import JamContainer from '@/src/components/ui/molecules/JamContainer/JamContainer';


import './JamReport.scss';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import { useJamReport } from '@/src/store/jam-report.context';
import { ChallengeMetricsRow } from '@/src/types/JamReport';

const ChallengeMetrics = () => {
  const { reportApiData } = useJamReport();
  const [tableData, setTableData] = useState<ChallengeMetricsRow[]>([]);

  const { t } = useTranslation();
  useEffect(() => {
    if (reportApiData) {
      const { challengeMetrics, challengeTitles } = reportApiData;
      if (challengeMetrics && challengeMetrics?.length > 0) {
        challengeMetrics.forEach((obj) => {
          obj.title = challengeTitles[obj.challengeId || ''];
        });
        setTableData(challengeMetrics);
      }
    }
  }, [reportApiData]);

  return (
    <JamContainer>
      <Box>
        <h2>{t(i18nKeys.eventReport.challengeTable.heading)}</h2>
      </Box>
      <Table
        contentDensity="compact"
        columnDefinitions={[
          {
            header: t(i18nKeys.eventReport.challengeTable.challengeTitle),
            cell: (item) => item?.title || '-',
            minWidth: 200,
          },
          {
            header: (
              <>
                {t(i18nKeys.eventReport.challengeTable.teams)}
                <br />
                {t(i18nKeys.eventReport.challengeTable.started)}
              </>
            ),
            cell: (item) => item?.numTeamsStarted || 0,
            minWidth: 100,
          },
          {
            header: (
              <>
                {t(i18nKeys.eventReport.challengeTable.teams)}
                <br />
                {t(i18nKeys.eventReport.challengeTable.solved)}
              </>
            ),
            cell: (item) => item?.numTeamsSolved || 0,
            minWidth: 100,
          },
          {
            header: (
              <p>
                {t(i18nKeys.eventReport.challengeTable.teams)}
                <br /> {t(i18nKeys.eventReport.challengeTable.attempted)}
              </p>
            ),
            cell: (item) => item?.numTeamsAttempted || 0,
            minWidth: 100,
          },
          {
            header: (
              <>
                {t(i18nKeys.eventReport.challengeTable.clue)}
                <br />
                {t(i18nKeys.eventReport.challengeTable.used)}
              </>
            ),
            cell: (item) => item?.numCluesRequested || 0,
            minWidth: 100,
          },
          {
            header: (
              <>
                {t(i18nKeys.eventReport.challengeTable.avgSolve)}
                <br />
                {t(i18nKeys.eventReport.challengeTable.timeAll)}
              </>
            ),
            cell: (item) => (item?.globalSolveTime?.mean && `${Math.floor(item?.globalSolveTime?.mean / 60)}m`) || '-',
            minWidth: 130,
          },
          {
            header: (
              <>
                {t(i18nKeys.eventReport.challengeTable.avgSolve)}
                <br />
                {t(i18nKeys.eventReport.challengeTable.timeEvent)}
              </>
            ),
            cell: (item) =>
              (item?.timeToCompletedChallenge?.mean &&
              Math.floor(item?.timeToCompletedChallenge?.mean / (60 * 1000)) !== 0
                ? `${Math.floor(item?.timeToCompletedChallenge?.mean / (60 * 1000))}m`
                : '0s') || '0s',
            minWidth: 130,
          },
          {
            header: (
              <>
                {t(i18nKeys.eventReport.challengeTable.timeToFirst)}
                <br />
                {t(i18nKeys.eventReport.challengeTable.attempt)}
              </>
            ),
            cell: (item) =>
              (item?.timeToFirstAttempt?.mean && Math.floor(item?.timeToFirstAttempt?.mean / (60 * 1000)) !== 0
                ? `${Math.floor(item?.timeToFirstAttempt?.mean / (60 * 1000))}m`
                : '0s') || '0s',
            minWidth: 130,
          },
          {
            header: (
              <>
                {t(i18nKeys.eventReport.challengeTable.services)}
                <br />
                {t(i18nKeys.eventReport.challengeTable.used)}
              </>
            ),
            cell: (item) => (item?.awsServicesUsed?.length > 0 ? item?.awsServicesUsed.join(', ') : '-'),
            minWidth: 130,
          },
        ]}
        items={tableData || []}
        loadingText={t(i18nKeys.eventReport.loadigResource)}
        sortingDisabled
        variant="embedded"
      />
    </JamContainer>
  );
};

export default ChallengeMetrics;
