import { EditEventActions, useEditEvent } from '@/src/store/edit-event.context';
import { useUser } from '@/src/store/user.context';
import { Event } from '@/src/types/Event';
import { EventPrivacyType } from '@/src/types/EventPrivacyType';
import { i18nKeys } from '@/src/utils/i18n.utils';
import {
  Box,
  ExpandableSection,
  Grid,
  Header,
  Tiles,
  FormField,
  Textarea,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import { BaseChangeDetail } from '@amzn/awsui-components-react/polaris/input/interfaces';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface PrivacyTypeProps {
  target: Event | undefined;
}

interface PublicEventDetailsInputProps {
  target: Event | undefined;
}

const MAX_PUBLIC_DESCRIPTION_LENGTH = 1000;
const MIN_PUBLIC_DESCRIPTION_LENGTH = 40;
const PublicEventDetailsInput: React.FC<PublicEventDetailsInputProps> = ({ target }) => {
  const { t } = useTranslation();
  const { handleUpdateEditEvent } = useEditEvent();
  const [error, setError] = useState('');
  const [showError, setShowError] = useState(false);

  const handleTextAreaChange = useCallback(
    ({ detail }) => {
      const description = (detail as BaseChangeDetail).value;
      // don't allow more than a 1000 chars
      if (description.length > MAX_PUBLIC_DESCRIPTION_LENGTH) {
        setError(
          t(i18nKeys.events.eventDetails.labels.publicEventSettings.maxDescriptionError, {
            MAX_PUBLIC_DESCRIPTION_LENGTH,
          })
        );
        setShowError(true);
        return;
      }
      if (description.length < MIN_PUBLIC_DESCRIPTION_LENGTH) {
        setError(
          t(i18nKeys.events.eventDetails.labels.publicEventSettings.minDescriptionError, {
            MIN_PUBLIC_DESCRIPTION_LENGTH,
          })
        );
      } else {
        setError('');
        setShowError(false);
      }

      handleUpdateEditEvent(EditEventActions.PUBLIC_DESCRIPTION, detail.value);
    },
    [t]
  );

  const handleTextAreaBlur = useCallback(() => {
    if (error) {
      setShowError(true);
      return;
    }
    setShowError(false);
  }, [error]);

  return (
    <FormField
      label={<b>{t(i18nKeys.events.eventDetails.labels.publicEventSettings.description)}</b>}
      description={t(i18nKeys.events.eventDetails.labels.publicEventSettings.descriptionHelpText)}
      i18nStrings={{errorIconAriaLabel: t(i18nKeys.general.error)}}
      errorText={showError ? error : ''}>
      <Textarea
        value={target?.publicEventDetails.description ?? ''}
        onChange={handleTextAreaChange}
        onBlur={handleTextAreaBlur}
        placeholder={t(i18nKeys.events.eventDetails.labels.publicEventSettings.descriptionPlaceholder)}
      />
    </FormField>
  );
};

const PrivacyType: React.FC<PrivacyTypeProps> = ({ target }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { handleUpdateEditEvent, newEventMode } = useEditEvent();

  // privacy can not be edited
  const canEdit = newEventMode;

  const renderContent = () => {
    const AdminEventPrivacyTypes = Object.keys(EventPrivacyType).map((type) => ({
      label: t(i18nKeys.events.eventDetails.labels.evenPrivacyType[type as keyof typeof EventPrivacyType]),
      value: EventPrivacyType[type as keyof typeof EventPrivacyType],
      description: t(i18nKeys.events.eventDetails.labels.evenPrivacyTypeDesc[type as keyof typeof EventPrivacyType]),
    }));
    const EventPrivacyTypes = AdminEventPrivacyTypes.filter((f) => f.value !== EventPrivacyType.PUBLIC);

    if (!canEdit) {
      return <Box>{target?.eventPrivacyType}</Box>;
    }
    if (user) {
      return (
        <>
          <Tiles
            onChange={({ detail }) => {
              handleUpdateEditEvent(EditEventActions.EVENT_PRIVACY_TYPE, detail.value);
            }}
            value={target?.eventPrivacyType || ''}
            // Event support or Jam admin can create all the event types
            items={user.isEventAdmin || user.isSuperAdmin ? AdminEventPrivacyTypes : EventPrivacyTypes}
          />
        </>
      );
    }
    return null;
  };

  return (
    <ExpandableSection
      variant="container"
      defaultExpanded
      header={<Header variant="h2">{t(i18nKeys.events.eventDetails.headers.eventPrivacyType)}</Header>}>
      <SpaceBetween direction="vertical" size="l">
        <Grid gridDefinition={[{ colspan: 8 }]}>{renderContent()}</Grid>
        {user && canEdit && target?.eventPrivacyType === EventPrivacyType.PUBLIC && (
          <PublicEventDetailsInput target={target} />
        )}
      </SpaceBetween>
    </ExpandableSection>
  );
};

export default PrivacyType;
