/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import { PageHeader } from '../common/PageHeader';
import { Button, ButtonDropdown, ContentLayout, SpaceBetween } from '@amzn/awsui-components-react';
import { CHALLENGE_DETAILS_ROUTES, challengeRoute, resolve404Path, challengesRoute } from '../../routes';
import { i18nKeys } from '../../utils/i18n.utils';
import ChallengeSettingsDetails from './challengeDetailSections/challengeSettings/ChallengeSettingsDetails';
import { useChallenges } from '../../store/challenge.context';
import { useApi } from '../../store/api.context';
import { ConfirmModal } from '../common/ConfirmModal';
import { ChallengeUtils, ChallengeWarningResponse, MarkChallengeDefectiveRequest } from '../../types/Challenge';
import { useCreateChallenge } from '../../store/create-challenge.context';
import { useUser } from '../../store/user.context';
import ChallengeOverviewDetail from './challengeDetailSections/challengeSummary/Overview';
import LearningOutcomeDetail from './challengeDetailSections/challengeLearningOutcome/LearningOutcome';
import ReviewProgressTracker from './challengeDetailSections/challengeReviewCommon/ReviewProgressTracker';
import ReviewButtonPanel from './challengeDetailSections/challengeReviewCommon/ReviewButtonPanel';
import { preProdLogger } from '../../utils/log.utils';
import JamSpinner from '../common/JamSpinner';
import { customEventTrigger } from '../analytics/createEventTrigger';
import { Comment } from '../../../src/types/common';
import SwitchToLegacyComponent from '@/src/components/switch-to-legacy-component/SwitchToLegacyComponent';
import { RoutePath } from '@/src/RoutePath';
import { HybridConsolePrefix } from '@/src/utils/hybrid-console/index';
import ChallengeTestingDetail from './challengeDetailSections/challengeTesting/Testing';
import ChallengeTasksDetail from './challengeDetailSections/challengeTask/Tasks';
import ChallengeCFNTemplateDetail from './challengeDetailSections/challengeCFNTemplate/CFNTemplate';
import ChallengeResourcesDetail from './challengeDetailSections/challengeResources/Resources';
import ChallengeIAMPolicyDetail from './challengeDetailSections/challengeIAMPolicy/IAMPolicy';
import ChallengeNextStepsDetail from './challengeDetailSections/challengeNextSteps/NextSteps';
import ChallengeWikiDetail from './challengeDetailSections/challengeWiki/Wiki';
import Comments from '../common/Comments/Comments';
import ChallengeStatsDetails from './challengeDetailSections/challengeStats/ChallengeStatsDetails';
import ChallengeIssuesDetail from './challengeDetailSections/challengeIssues/Issues';
import ChallengeCollaboratorsDetail from './challengeDetailSections/challengeCollaborators/Collaborators';
import ChallengeIssuesDetailSingle from './challengeDetailSections/challengeIssues/SingleIssue';
import ChallengeSummaryDetails from './challengeDetailSections/challengeSummary/ChallengeSummaryDetails';
import ChallengeHelpDetail from './challengeDetailSections/challengeHelp/Help';
import { config } from '@/src/config/app-config';
import ChallengeFacilitatorNotesDetail from './challengeDetailSections/challengeFacilitatorNotes/FacilitatorNotes';
import ChallengeRevisionsDetail from './challengeDetailSections/challengeRevisions/Revisions';
import ChallengeTranslationDetails from './challengeDetailSections/challengeTranslation/ChallengeTranslationDetails';
import ChallengeFeedbackDetails from './challengeDetailSections/challengeFeedback/ChallengeFeedbackDetails';

const ChallengeDetails: React.FC = () => {
  const { t } = useTranslation();
  const { challengeId }: { challengeId: string } = useParams();
  const history = useHistory();
  const { user } = useUser();
  const { challengesApi } = useApi();
  const {
    challenge,
    getChallenge,
    updatePublicStatus,
    updateDemoStatus,
    archiveChallenge,
    unarchiveChallenge,
    addChallengeComment,
    updateChallengeComment,
    deleteChallengeComment,
    deleteChallenge,
  } = useChallenges();
  const { editMode, toggleChallengeEditMode, initializeNewChallenge, saveEditedChallenge } = useCreateChallenge();

  const locationPath = useLocation();
  const { pathname } = locationPath;

  const [loading, setLoading] = useState(true);

  // Modal Visibility Settings
  const [confirmDefectiveVisible, setConfirmDefectiveVisible] = useState(false);
  const [confirmShutoffVisible, setConfirmShutoffVisible] = useState(false);
  const [confirmArchiveVisible, setConfirmArchiveVisible] = useState(false);
  const [confirmPrivateVisble, setConfirmPrivateVisible] = useState(false);
  const [confirmDemoVisible, setConfirmDemoVisible] = useState(false);
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);

  // Modal Messages
  const [defectiveMessage, setDefectiveMessage] = useState('');
  const [defectiveYesLabel, setDefectiveYesLabel] = useState('');
  const [defectiveWarningToken, setDefectiveWarningToken] = useState('');

  const [privateMessage, setPrivateMessage] = useState('');
  const [privateYesLabel, setPrivateYesLabel] = useState('');

  const [demoMessage, setDemoMessage] = useState('');
  const [demoYesLabel, setDemoYesLAbel] = useState('');

  const [archiveMessage, setArchiveMessage] = useState('');
  const [archiveYesLabel, setArchiveYesLabel] = useState('');
  const [, setSummaryRoute] = useState(false);
  const [resourcesRoute, setResourcesRoute] = useState(false);
  const [iAmPolicyRoute, setIAmPolicyRoute] = useState(false);
  const [issueRoute, setIssueRoute] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState('');
  const [deleteYesLabel, setDeleteYesLabel] = useState('');
  const [translationRoute, setTranslationRoute] = useState(false);

  const [optionalDefectiveReason, setOptionalDefectiveReason] = useState('');

  const displayOverviewChallenges = [CHALLENGE_DETAILS_ROUTES.Tasks.wildcard().split('/')[3]];

  const comments: Comment[] = challenge?.props.comments ? challenge.props.comments : [];

  useEffect(() => {
    loadChallenge();
  }, [challengeId]);

  const isRouteMatch = (route: string, location: string): boolean => {
    // checking if location equals route or location equals /v2/route
    if (location === route || location === `${HybridConsolePrefix.Polaris}${route}`) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    // this will check the if route is summary and based on that we only show overview container to summary route
    const isSummeryRoute = isRouteMatch(
      CHALLENGE_DETAILS_ROUTES.Summary.replaceTokens([challengeId]),
      location.pathname
    );
    setSummaryRoute(isSummeryRoute);
    const isIssueRoute = isRouteMatch(CHALLENGE_DETAILS_ROUTES.Issues.replaceTokens([challengeId]), location.pathname);
    setIssueRoute(isIssueRoute);
    const isResourcesRoute = isRouteMatch(
      CHALLENGE_DETAILS_ROUTES.Assets.replaceTokens([challengeId]),
      location.pathname
    );
    setResourcesRoute(isResourcesRoute);
    const isIAmPolicyRoute = isRouteMatch(
      CHALLENGE_DETAILS_ROUTES.Assets.replaceTokens([challengeId]),
      location.pathname
    );
    setIAmPolicyRoute(isIAmPolicyRoute);
    const isTranslationRoute = isRouteMatch(
      CHALLENGE_DETAILS_ROUTES.Translation.replaceTokens([challengeId]),
      location.pathname
    );
    setTranslationRoute(isTranslationRoute);
  }, [location.pathname]);

  const loadChallenge = () => {
    setLoading(true);
    getChallenge(challengeId)
      .catch((err) => preProdLogger('Error loading challenge', err))
      .finally(() => setLoading(false));
  };

  /**
   * as AllJamRoutes does not take care
   * of details routes we need to redirect
   * un-authorized users explicitly to
   * 403 screen based on permissions
   * */
  if (user?.isOnlyBasicUser) {
    history.replace(RoutePath.JAM_403);
    return null;
  }

  if (!loading && history && challenge == null) {
    history.push(resolve404Path(challengeRoute.resolvePath(challengeId)));
    return null;
  }

  // const comments: Comment[] = challenge?.props.comments ? challenge.props.comments : [];

  // Currently we are hiding the components because currently have to redirect these component into v1 but later on we will use these components
  const renderChildView = () => {
    return challenge ? (
      <Switch>
        <Route path={CHALLENGE_DETAILS_ROUTES.LearningOutcome.wildcard()}>
          <LearningOutcomeDetail challenge={challenge} />
        </Route>
        <Route path={CHALLENGE_DETAILS_ROUTES.Settings.wildcard()}>
          <ChallengeSettingsDetails challenge={challenge} />
        </Route>
        <Route path={CHALLENGE_DETAILS_ROUTES.Tasks.wildcard()}>
          {config.showAdminPages ? <ChallengeTasksDetail challenge={challenge} /> : <SwitchToLegacyComponent />}
        </Route>
        <Route path={CHALLENGE_DETAILS_ROUTES.Assets.wildcard()}>
          {config.showAdminPages ? <ChallengeResourcesDetail challenge={challenge} /> : <SwitchToLegacyComponent />}
        </Route>
        <Route path={CHALLENGE_DETAILS_ROUTES.IamPolicy.wildcard()}>
          {config.showAdminPages ? <ChallengeIAMPolicyDetail challenge={challenge} /> : <SwitchToLegacyComponent />}
        </Route>
        <Route path={CHALLENGE_DETAILS_ROUTES.CfnTemplate.wildcard()}>
          {config.showAdminPages ? <ChallengeCFNTemplateDetail challenge={challenge} /> : <SwitchToLegacyComponent />}
        </Route>
        <Route path={CHALLENGE_DETAILS_ROUTES.NextSteps.wildcard()}>
          {config.showAdminPages ? <ChallengeNextStepsDetail challenge={challenge} /> : <SwitchToLegacyComponent />}
        </Route>
        <Route path={CHALLENGE_DETAILS_ROUTES.Wiki.wildcard()}>
          {config.showAdminPages ? <ChallengeWikiDetail challenge={challenge} /> : <SwitchToLegacyComponent />}
        </Route>
        <Route path={CHALLENGE_DETAILS_ROUTES.Collaborators.wildcard()}>
          {config.showAdminPages ? <ChallengeCollaboratorsDetail challenge={challenge} /> : <SwitchToLegacyComponent />}
        </Route>
        <Route path={CHALLENGE_DETAILS_ROUTES.Issues.wildcard()} exact>
          {config.showAdminPages ? <ChallengeIssuesDetail challenge={challenge} /> : <SwitchToLegacyComponent />}
        </Route>
        <Route path={CHALLENGE_DETAILS_ROUTES.IssuesSingle.wildcard()}>
          <ChallengeIssuesDetailSingle challenge={challenge} />
        </Route>
        <Route path={CHALLENGE_DETAILS_ROUTES.Comments.wildcard()}>
          {config.showAdminPages ? (
            <Comments
              comments={comments || []}
              addNewComment={addChallengeComment}
              deleteComment={deleteChallengeComment}
              updateComment={updateChallengeComment}
            />
          ) : (
            <SwitchToLegacyComponent />
          )}
        </Route>
        <Route path={CHALLENGE_DETAILS_ROUTES.Testing.wildcard()}>
          {config.showAdminPages ? <ChallengeTestingDetail challenge={challenge} /> : <SwitchToLegacyComponent />}
        </Route>
        <Route path={CHALLENGE_DETAILS_ROUTES.Feedback.wildcard()}>
          <ChallengeFeedbackDetails challenge={challenge} />
          {/* {SwitchToLegacyComponent} */}
        </Route>
        <Route path={CHALLENGE_DETAILS_ROUTES.Stats.wildcard()}>
          <ChallengeStatsDetails challenge={challenge} />
        </Route>
        <Route path={CHALLENGE_DETAILS_ROUTES.Revisions.wildcard()}>
          <ChallengeRevisionsDetail />
          {/* {SwitchToLegacyComponent} */}
        </Route>
        <Route path={CHALLENGE_DETAILS_ROUTES.Reviews.wildcard()}>
          {/* <ChallengeReviewDetail /> */}
          {SwitchToLegacyComponent}
        </Route>
        <Route path={CHALLENGE_DETAILS_ROUTES.Translation.wildcard()}>
          {config.showAdminPages ? <ChallengeTranslationDetails challenge={challenge} /> : <SwitchToLegacyComponent />}
        </Route>
        <Route path={CHALLENGE_DETAILS_ROUTES.FacilitatorNotes.wildcard()}>
          {<ChallengeFacilitatorNotesDetail challenge={challenge} />}
          {/* {SwitchToLegacyComponent} */}
        </Route>
        <Route path={CHALLENGE_DETAILS_ROUTES.Help.wildcard()}>
          {/* <ChallengeHelpDetail /> */}
          {/* {SwitchToLegacyComponent} */}
          {config.showAdminPages ? <ChallengeHelpDetail /> : <SwitchToLegacyComponent />}
        </Route>
        {/* Summary must be last */}
        <Route path={CHALLENGE_DETAILS_ROUTES.Summary.wildcard()}>
          <ChallengeSummaryDetails challenge={challenge} />
        </Route>
      </Switch>
    ) : null;
  };

  const shouldShowButton = (buttonName: string): boolean => {
    if (editMode) {
      return false;
    }

    if (user && challenge) {
      switch (buttonName) {
        case 'critical-issue':
          // return this.challengeGlobalStats.highestIssueSeverity === ChallengeIssueSeverity.CRITICAL;
          break;
        case 'clone':
          return false;
        case 'delete':
          return ChallengeUtils.isChallengeAdmin(user);
        case 'archive':
          return ChallengeUtils.isChallengeAdmin(user);
        case 'defective':
          return ChallengeUtils.isChallengeAdmin(user);
        case 'public':
          return ChallengeUtils.isChallengeAdmin(user);
        case 'demo':
          return ChallengeUtils.isChallengeAdmin(user);
        default:
          return false;
      }
    }

    return false;
  };

  const handleActionClick = (clickEvent: any) => {
    switch (clickEvent.detail.id) {
      case 'defective':
        customEventTrigger('linkclick', 'Defective', window.location.href, 'Actions - Defective', {});
        void setDefectiveMessages();
        setConfirmDefectiveVisible(true);
        break;
      case 'shutOffLabDeployment':
        customEventTrigger(
          'linkclick',
          'shutOffLabDeployment',
          window.location.href,
          'Actions - shutOffLabDeployment',
          {}
        );
        setConfirmShutoffVisible(true);
        break;
      case 'public':
        customEventTrigger('linkclick', 'public', window.location.href, 'Actions - public', {});
        setPrivateMessages();
        setConfirmPrivateVisible(true);
        break;
      case 'demo':
        customEventTrigger('linkclick', 'Demo', window.location.href, 'Actions - demo', {});
        setDemoMessages();
        setConfirmDemoVisible(true);
        break;
      case 'archive':
        customEventTrigger('linkclick', 'Archive', window.location.href, 'Actions - archive', {});
        setArchiveMessages();
        setConfirmArchiveVisible(true);
        break;
      case 'delete':
        customEventTrigger('linkclick', 'delete', window.location.href, 'Actions - delete', {});
        setDeleteMessages();
        setConfirmDeleteVisible(true);
        break;
    }
    return;
  };

  const setDefectiveMessages = async () => {
    const newDefectiveState = !challenge?.isDefective;
    let message = '';

    if (!newDefectiveState) {
      message = t(i18nKeys.challenges.challengeDetails.messages.removeDefectiveState);
      setDefectiveYesLabel(t(i18nKeys.challenges.challengeDetails.yesLabels.removeDefectiveState));
    } else {
      message = t(i18nKeys.challenges.challengeDetails.messages.markDefectiveState);
      setDefectiveYesLabel(t(i18nKeys.challenges.challengeDetails.yesLabels.markDefectiveState));
    }

    let warningToken = '';

    // i.e. if this challenge is transitioning from not defective to defective
    if (newDefectiveState === true && !challenge?.isDefective) {
      /* Get a list of warnings and a token associated with marking this challenge as defective. */
      const warning: ChallengeWarningResponse = await challengesApi.getMarkDefectiveWarnings(challengeId, false);

      warningToken = warning.warningToken;

      if (warning.warnings !== undefined && warning.warnings.length > 0) {
        message += '\n\nWarning: ';

        for (const warningMessage of warning.warnings) {
          message += `${warningMessage}\n`;
        }
      }
    }
    setDefectiveWarningToken(warningToken);
    setDefectiveMessage(message);
  };

  const handleDefectiveConfirmation = async () => {
    const request: MarkChallengeDefectiveRequest = {
      challengeId,
      defective: !challenge?.isDefective,
      warningToken: defectiveWarningToken,
      optionalComment: optionalDefectiveReason,
    };

    await challengesApi.updateDefectiveStatus(challengeId, request);
    setConfirmDefectiveVisible(false);
  };

  const handleShutOffConfirmation = () => {
    // TODO: After integrating Lab Shut Off APIs
  };

  const isVisibleOverview = () => {
    const paths = pathname.split('/');
    const pageName = paths[paths.length - 1];
    return displayOverviewChallenges.includes(pageName);
  };

  const setPrivateMessages = () => {
    if (!challenge?.isPublic) {
      setPrivateMessage(t(i18nKeys.challenges.challengeDetails.messages.markChallengePublic));
      setPrivateYesLabel(t(i18nKeys.challenges.challengeDetails.yesLabels.markChallengePublic));
    } else {
      setPrivateMessage(t(i18nKeys.challenges.challengeDetails.messages.markChallengePrivate));
      setPrivateYesLabel(t(i18nKeys.challenges.challengeDetails.yesLabels.markChallengePrivate));
    }
  };

  const handlePrivateConfirmation = () => {
    void updatePublicStatus(challengeId, !challenge?.isPublic);
    setConfirmPrivateVisible(false);
  };

  const setDemoMessages = () => {
    const newDemoState = !challenge?.isDemo;

    let newDemoMessage = '';

    if (!newDemoState) {
      newDemoMessage = t(i18nKeys.challenges.challengeDetails.messages.removeAsExampleChallenge);
      setDemoYesLAbel(t(i18nKeys.challenges.challengeDetails.yesLabels.removeAsExampleChallenge));
    } else {
      newDemoMessage = t(i18nKeys.challenges.challengeDetails.messages.markAsExampleChallenge);
      if (!challenge?.isPublic) {
        newDemoMessage += t(i18nKeys.challenges.challengeDetails.messages.markAsNewExampleChallenge);
      }
      setDefectiveYesLabel(t(i18nKeys.challenges.challengeDetails.yesLabels.markAsExampleChallenge));
    }

    setDemoMessage(newDemoMessage);
  };

  const handleDemoConfirmation = () => {
    void updateDemoStatus(challengeId, !challenge?.isDemo);
    setConfirmDemoVisible(false);
  };

  const setArchiveMessages = () => {
    if (challenge?.isArchived) {
      setArchiveMessage(t(i18nKeys.challenges.challengeDetails.messages.unarchiveChallenge));
      setArchiveYesLabel(t(i18nKeys.challenges.challengeDetails.yesLabels.unarchiveChallenge));
    } else {
      setArchiveMessage(t(i18nKeys.challenges.challengeDetails.messages.archiveChallenge));
      setArchiveYesLabel(t(i18nKeys.challenges.challengeDetails.yesLabels.archiveChallenge));
    }
  };

  const handleArchiveConfirmation = () => {
    if (challenge?.isArchived) {
      void unarchiveChallenge(challengeId);
    } else {
      void archiveChallenge(challengeId);
    }
    setConfirmArchiveVisible(false);
  };

  const handleToggleEdit = () => {
    customEventTrigger('click', 'Edit Challenge', window.location.href, 'Edit Challenge', {});
    if (challenge) {
      initializeNewChallenge();
    }

    toggleChallengeEditMode(challenge || null);
  };

  const handleDeleteChallenge = () => {
    customEventTrigger('click', 'Delete challenge', window.location.href, 'Actions - Delete challenge', {});
    void deleteChallenge(challengeId);
    setConfirmDeleteVisible(false);
    if (history) {
      history.push(challengesRoute.path);
    }
  };

  const setDeleteMessages = () => {
    setDeleteMessage(t(i18nKeys.challenges.challengeDetails.messages.deleteChallenge));
    setDeleteYesLabel(t(i18nKeys.challenges.challengeDetails.yesLabels.delete));
  };

  const handleSaveChallenge = () => {
    customEventTrigger('click', 'Save Challenge', window.location.href, 'Actions - Save Challenge', {});
    setLoading(true);
    saveEditedChallenge()
      .then((c) => {
        if (c) {
          getChallenge(c.challengeId || '')
            .then(() => toggleChallengeEditMode(null))
            .catch((err) => preProdLogger(err));
        }
      })
      .catch((err) => preProdLogger(err))
      .finally(() => setLoading(false));
  };

  return (
    <React.Fragment>
      {loading && <JamSpinner />}
      <ReviewProgressTracker />
      <ContentLayout
        header={
          <PageHeader
            title={challenge?.props.title || 'Untitled Challenge'}
            buttons={[
              // eslint-disable-next-line
              <SpaceBetween size="xxs" direction="horizontal">
                <ReviewButtonPanel />
                <span key="challenge-details-button">
                  {shouldShowButton('public') && (
                    <ButtonDropdown
                      className="mr-5"
                      onItemClick={handleActionClick}
                      items={[
                        // Non-null assertions necessary due to i18nKeys being string | undefined and ItemOrGroup requiring text to be just string
                        // i18nKey should never be undefined
                        {
                          text:
                            (challenge?.isDefective
                              ? t(i18nKeys.challenges.challengeDetails.buttons.removeChallengeDefective)
                              : t(i18nKeys.challenges.challengeDetails.buttons.markChallengeDefective)) || '',
                          id: 'defective',
                          disabled: !shouldShowButton('defective'),
                        },
                        {
                          text: t(i18nKeys.challenges.challengeDetails.buttons.shutOffLabDeployment) || '',
                          id: 'shutOffLabDeployment',
                          disabled: false,
                        },
                        {
                          text:
                            (challenge?.isPrivate
                              ? t(i18nKeys.challenges.challengeDetails.buttons.markChallengePublic)
                              : t(i18nKeys.challenges.challengeDetails.buttons.markChallengePrivate)) || '',
                          id: 'public',
                          disabled: !shouldShowButton('public'),
                        },
                        {
                          text:
                            (challenge?.isDemo
                              ? t(i18nKeys.challenges.challengeDetails.buttons.removeAsExampleChallenge)
                              : t(i18nKeys.challenges.challengeDetails.buttons.markAsExampleChallenge)) || '',
                          id: 'demo',
                          disabled: !shouldShowButton('demo'),
                        },
                        {
                          text:
                            (challenge?.isArchived
                              ? t(i18nKeys.challenges.challengeDetails.buttons.unarchiveChallenge)
                              : t(i18nKeys.challenges.challengeDetails.buttons.archiveChallenge)) || '',
                          id: 'archive',
                          disabled: !shouldShowButton('archive'),
                        },
                        {
                          text: t(i18nKeys.challenges.challengeDetails.buttons.deleteChallenge) || '',
                          id: 'delete',
                          disabled: !shouldShowButton('delete'),
                        },
                      ]}>
                      {t(i18nKeys.events.eventDetails.buttons.actionsPlaceholder)}
                    </ButtonDropdown>
                  )}
                  {!editMode &&
                    !issueRoute &&
                    !resourcesRoute &&
                    !translationRoute &&
                    ChallengeUtils.isCollaborator(challenge, user) && (
                      <Button className="mr-5" onClick={() => handleToggleEdit()} disabled={loading}>
                        {t(i18nKeys.general.edit)}
                      </Button>
                    )}
                  {editMode && !resourcesRoute && ChallengeUtils.isCollaborator(challenge, user) && (
                    <React.Fragment>
                      <Button
                        className="mr-5"
                        variant="link"
                        onClick={() => toggleChallengeEditMode(challenge || null)}
                        disabled={loading}>
                        {t(i18nKeys.general.cancel)}
                      </Button>
                      <Button className="mr-5" variant="primary" onClick={handleSaveChallenge} disabled={loading}>
                        {t(i18nKeys.general.save)}
                      </Button>
                    </React.Fragment>
                  )}
                  {shouldShowButton('delete') && !resourcesRoute && iAmPolicyRoute && (
                    <Button className="mr-5" onClick={() => setConfirmDeleteVisible(true)}>
                      {t(i18nKeys.general.delete)}
                    </Button>
                  )}
                </span>
              </SpaceBetween>,
            ]}
          />
        }>
        <ConfirmModal
          message={defectiveMessage}
          visible={confirmDefectiveVisible}
          confirmBtnLabel={defectiveYesLabel}
          textInput={optionalDefectiveReason}
          onTextInput={setOptionalDefectiveReason}
          onConfirm={handleDefectiveConfirmation}
          onCancel={() => setConfirmDefectiveVisible(false)}
        />
        <ConfirmModal
          visible={confirmShutoffVisible}
          confirmBtnLabel={t(i18nKeys.events.eventDetails.buttons.yesDelete)}
          onConfirm={handleShutOffConfirmation}
          onCancel={() => setConfirmShutoffVisible(false)}
        />
        <ConfirmModal
          message={privateMessage}
          visible={confirmPrivateVisble}
          confirmBtnLabel={privateYesLabel}
          onConfirm={handlePrivateConfirmation}
          onCancel={() => setConfirmPrivateVisible(false)}
        />
        <ConfirmModal
          message={demoMessage}
          visible={confirmDemoVisible}
          confirmBtnLabel={demoYesLabel}
          onConfirm={handleDemoConfirmation}
          onCancel={() => setConfirmDemoVisible(false)}
        />
        <ConfirmModal
          message={archiveMessage}
          visible={confirmArchiveVisible}
          confirmBtnLabel={archiveYesLabel}
          onConfirm={handleArchiveConfirmation}
          onCancel={() => setConfirmArchiveVisible(false)}
        />
        <ConfirmModal
          message={deleteMessage}
          visible={confirmDeleteVisible}
          confirmBtnLabel={deleteYesLabel}
          onConfirm={handleDeleteChallenge}
          onCancel={() => setConfirmDeleteVisible(false)}
        />
        <div style={{ marginTop: '1rem' }}>
          <SpaceBetween size="s">
            {isVisibleOverview() && <ChallengeOverviewDetail />}
            {renderChildView()}
          </SpaceBetween>
        </div>
      </ContentLayout>
    </React.Fragment>
  );
};

export default ChallengeDetails;
