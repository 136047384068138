import React from 'react';
import { ChallengeDescriptor, ChallengeListItem } from '../../../types/Challenge';
import './BrowseChallengesInSplitPanel.scss';
import BrowseChallenges from './BrowseChallenges';

interface BrowseChallengesInSplitPanelProps {
  toggleChallengeInfo: (challengeItem: ChallengeListItem) => void;
  currentChallengeDescriptors: ChallengeDescriptor[];
  handleChallengeAction: (challenges: ChallengeDescriptor[]) => void;
  excludedChallenges?: ChallengeDescriptor[];
}

const BrowseChallengesInSplitPanel: React.FC<BrowseChallengesInSplitPanelProps> = ({
  toggleChallengeInfo,
  currentChallengeDescriptors,
  handleChallengeAction,
  excludedChallenges = [],
}) => {
  return (
    <div className="browse-challenges">
      <BrowseChallenges
        showSelectedSplitPanel={false}
        toggleChallengeInfo={toggleChallengeInfo}
        currentChallengeDescriptors={currentChallengeDescriptors}
        handleChallengeAction={handleChallengeAction}
        excludedChallenges={excludedChallenges}
        editMode
      />
    </div>
  );
};
export default BrowseChallengesInSplitPanel;
