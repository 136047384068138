import { Box, ExpandableSection, Grid, Header, Link, Button } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Event, getEventListStatus } from '../../../../types/Event';
import { i18nKeys } from '../../../../utils/i18n.utils';
import LabShutoffStatusBadge from '../../../common/LabShutoffStatusBadge';
import LabStatusCountdown from '../../eventsCommon/labStatusCountdown/LabStatusCountdown';
import SecretKey from '../../../common/SecretKey';
import { MY_JAM_PREFIX } from '@/src/constants/api';
import { useApi } from '@/src/store/api.context';
import { useUser } from '@/src/store/user.context';
import { useFlashbars } from '@/src/store/flashbar.context';
import { GetEventByNameResponse } from '@/src/api';
import { copyToClipboard } from '@/src/utils/copy-to-clipboard';

interface AccessProps {
  event: Event | undefined;
  onAddFlashbar: (error: string) => void;
  // isGuestUser: (user: common.Nullable<User>) => boolean;
}

const Access: React.FC<AccessProps> = ({ event }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { addErrorFlashbar, addSuccessFlashbar } = useFlashbars();
  // SecretKey accepts a String/undefined param but secretKeys urls are nullable strings
  // Typically checking before rendering SecretKey suffices, however due to ternary nature of this component linting
  // did not allow us to do so. Moving the ternary to a const and checking for truthy solves for that
  const secretKeyUrl = event?.test ? event?.testUrlWithSecretKey : event?.url;

  // create URL to append with path
  const hostName = window.location.hostname;
  const protocol = window.location.protocol;
  let hostURL = `${protocol}//${hostName}`;
  const port = window.location.port;
  hostURL = hostName === 'localhost' || hostName === '127.0.0.1' ? `${hostURL}:${port}` : hostURL; // create URL based on local and live URL port

  const prefix = `/${MY_JAM_PREFIX}`;

  const liveURL = `${hostURL}${prefix}${event?.name}`;
  const liveURLLink = `${prefix}${event?.name}`;
  const testURL = `${hostURL}${event?.testUrlWithMaskedSecretKey}`;
  const testURLLink = `${event?.testUrlWithSecretKey}`;

  const { eventsApi } = useApi();

  const [isCreatingTestEvent, setIsCreatingTestEvent] = useState(false);
  const [createTestEventSuccess, setCreateTestEventSuccess] = useState(false);

  const handleCreateTestEvent = async () => {
    setIsCreatingTestEvent(true);
    try {
      await eventsApi.createTestEvent(event);
      setCreateTestEventSuccess(true);
      window.location.reload();
    } catch (error) {
      setCreateTestEventSuccess(false);
      addErrorFlashbar('Failed to create test event');
      throw error;
    }
  };

  const [testEvent, setTestEvent] = useState<GetEventByNameResponse | undefined>();

  useEffect(() => {
    const fetchTestEvent = async () => {
      if (event && event.testCloneEventName) {
        try {
          const response = await eventsApi.getEvent(event.testCloneEventName);
          setTestEvent(response);
        } catch (error) {
          addErrorFlashbar('Failed to get test event');
        }
      }
    };

    if (event && event.testCloneEventName) {
      void fetchTestEvent();
    }
  }, []);

  const copySecretKey = (secretKey: string) => {
    copyToClipboard(secretKey);
    addSuccessFlashbar(t(i18nKeys.events.eventDetails.labels.secretKey.message));
  };

  const copyEventLink = (eventUrl: string) => {
    copyToClipboard(eventUrl);
    addSuccessFlashbar(t(i18nKeys.clipboard.eventLinkCopied));
  };

  return (
    <React.Fragment>
      {event?.approved && (
        <ExpandableSection
          variant="container"
          defaultExpanded
          header={<Header variant="h2">{t(i18nKeys.events.eventDetails.headers.access)}</Header>}>
          <div style={{ paddingBottom: '15px' }} className="container-table-row">
            {t(i18nKeys.events.eventDetails.messages.eventAccess)}
            <div style={{ paddingTop: '5px' }}>
              <Box>
                1. <strong>{t(i18nKeys.events.eventDetails.headers.link)}:</strong>{' '}
                {t(i18nKeys.events.eventDetails.messages.eventAccessOption1)}
                <br />
              </Box>
              {event?.eventCode && (
                <Box>
                  2. <strong>{t(i18nKeys.events.eventDetails.headers.secretKey)}:</strong>{' '}
                  {t(i18nKeys.events.eventDetails.messages.eventAccessOption2)}
                </Box>
              )}
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {/* Left side */}
            <div style={{ flex: 1 }}>
              <Box margin={{ top: 'l' }}>
                {event.test}
                <div className="container-table-header">
                  <Grid gridDefinition={[{ colspan: 3 }, { colspan: 9 }]}>
                    <div>
                      <strong>{t(i18nKeys.events.eventDetails.headers.eventType.header)}</strong>
                    </div>
                    {event && !event?.test ? (
                      <div>{t(i18nKeys.events.eventDetails.labels.liveEvent)}</div>
                    ) : (
                      <div>{t(i18nKeys.events.eventDetails.labels.testEvent)}</div>
                    )}
                  </Grid>
                </div>
              </Box>
              {(!event?.test ? event?.urlWithMaskedSecretKey : event?.testUrlWithMaskedSecretKey) && (
                <div style={{ padding: '10px 8px 10px 0px' }} className="container-table-row container-table-row1">
                  <Grid gridDefinition={[{ colspan: 3 }, { colspan: 9 }]}>
                    <React.Fragment>
                      <div>
                        <strong>{t(i18nKeys.events.eventDetails.headers.link)}</strong>
                      </div>
                      {event && !event?.test
                        ? secretKeyUrl && (
                            <div>
                              <Link external href={liveURLLink}>
                                {liveURL}
                              </Link>
                              <div style={{ paddingTop: '10px' }}>
                                <Button onClick={() => copyEventLink(liveURL)}>
                                  {t(i18nKeys.events.eventDetails.buttons.copyEventLink)}
                                </Button>
                              </div>
                            </div>
                          )
                        : event?.testUrlWithSecretKey && (
                            <div>
                              <Link external href={testURLLink}>
                                {testURL}
                              </Link>
                            </div>
                          )}
                    </React.Fragment>
                  </Grid>
                </div>
              )}
              {(!event?.test ? event?.eventCode : event?.testCloneCode) && (
                <div style={{ padding: '10px 0px 10px 0px' }} className="container-table-row">
                  <Grid gridDefinition={[{ colspan: 3 }, { colspan: 9 }]}>
                    <React.Fragment>
                      <div>
                        <strong>{t(i18nKeys.events.eventDetails.headers.secretKey)}</strong>
                      </div>
                      {event && !event?.test
                        ? event?.eventCode && (
                            <div>
                              <SecretKey secretKey={event?.eventCode} />
                              <div style={{ paddingTop: '10px' }}>
                                <Button onClick={() => copySecretKey(event?.eventCode ?? '')}>
                                  {t(i18nKeys.events.eventDetails.buttons.copySecretKey)}
                                </Button>
                              </div>
                            </div>
                          )
                        : event?.testCloneCode && (
                            <div>
                              <SecretKey secretKey={event?.testCloneCode} />
                            </div>
                          )}
                    </React.Fragment>
                  </Grid>
                </div>
              )}
              <div style={{ padding: '10px 0px 10px 0px' }} className="container-table-row">
                <Grid gridDefinition={[{ colspan: 3 }, { colspan: 9 }]}>
                  <React.Fragment>
                    <div>
                      <strong>{t(i18nKeys.events.eventDetails.headers.status)}</strong>
                    </div>
                    {event && !event?.test ? (
                      <div>
                        <LabStatusCountdown
                          isTest={event?.test}
                          includeSeconds={false}
                          inline
                          status={getEventListStatus(event)}
                          labStartTime={event.labStartTime}
                          labEndTime={event.labEndTime}
                        />
                        <span style={{ marginRight: '20px' }} />
                        <LabShutoffStatusBadge
                          challengeDescriptors={event.challengeDescriptors}
                          shutoffStatus={event.shutoffStatus}
                          testEventShutoffStatus={event.testEventShutoffStatus}
                        />
                      </div>
                    ) : (
                      <div>
                        <LabStatusCountdown
                          isTest
                          includeSeconds={false}
                          status={getEventListStatus(event)}
                          labStartTime={event.labStartTime}
                          labEndTime={event.labEndTime}
                          inline
                        />
                        <span style={{ marginRight: '20px' }} />
                        <LabShutoffStatusBadge
                          challengeDescriptors={event.challengeDescriptors}
                          shutoffStatus={event.shutoffStatus}
                          testEventShutoffStatus={event.testEventShutoffStatus}
                          test
                        />
                      </div>
                    )}
                  </React.Fragment>
                </Grid>
              </div>
            </div>
            {/* Right side */}
            {event && event.testCloneEventName ? (
              <div style={{ flex: 1 }}>
                <Box margin={{ top: 'l' }}>
                  {event.test}
                  <div className="container-table-header">
                    <Grid gridDefinition={[{ colspan: 12 }]}>
                      <div>{t(i18nKeys.events.eventDetails.labels.testEvent)}</div>
                    </Grid>
                  </div>
                </Box>
                <div style={{ padding: '10px 50px 10px 0px' }} className="container-table-row">
                  <Grid gridDefinition={[{ colspan: 10 }]}>
                    <React.Fragment>
                      <Link external href={MY_JAM_PREFIX + (event?.testUrlWithSecretKey ?? '')}>
                        {`${hostURL}${MY_JAM_PREFIX}${event?.testUrlWithMaskedSecretKey}`}
                      </Link>
                      <div style={{ marginTop: '-10px' }}>
                        <Button onClick={() => copyEventLink(testURL)}>
                          {t(i18nKeys.events.eventDetails.buttons.copyEventLink)}
                        </Button>
                      </div>
                    </React.Fragment>
                  </Grid>
                </div>
                <div style={{ padding: '10px 0px 10px 0px' }} className="container-table-row">
                  <Grid gridDefinition={[{ colspan: 12 }]}>
                    <div>
                      <SecretKey secretKey={event.testCloneEventName} />
                      <div style={{ paddingTop: '10px' }}>
                        <Button onClick={() => copySecretKey(event?.testCloneEventName ?? '')}>
                          {t(i18nKeys.events.eventDetails.buttons.copySecretKey)}
                        </Button>
                      </div>
                    </div>
                  </Grid>
                </div>
                <div style={{ padding: '10px 0px 10px 0px' }} className="container-table-row">
                  <Grid gridDefinition={[{ colspan: 12 }]}>
                    <React.Fragment>
                      <div>
                        <LabStatusCountdown
                          isTest
                          includeSeconds={false}
                          status={getEventListStatus(testEvent?.event)}
                          labStartTime={testEvent?.event.labStartTime ?? ''}
                          labEndTime={testEvent?.event.labEndTime ?? ''}
                          inline
                        />
                        <span style={{ marginRight: '20px' }} />
                        <LabShutoffStatusBadge
                          challengeDescriptors={testEvent?.event.challengeDescriptors}
                          shutoffStatus={testEvent?.event.testEventShutoffStatus}
                          testEventShutoffStatus={testEvent?.event.testEventShutoffStatus}
                          test
                        />
                      </div>
                    </React.Fragment>
                  </Grid>
                </div>
              </div>
            ) : event &&
              !event.testCloneEventName &&
              !createTestEventSuccess &&
              !event.test &&
              !event.isGuestUser(user) ? (
              <div style={{ flex: 1 }}>
                <Box margin={{ top: 'l' }}>
                  {event.test}
                  <div className="container-table-header" style={{ paddingLeft: '17px' }}>
                    <Grid gridDefinition={[{ colspan: 12 }]}>
                      <div>{t(i18nKeys.events.eventDetails.labels.testEvent)}</div>
                    </Grid>
                  </div>
                </Box>
                <div style={{ padding: '18px 8px 10px 0px' }}>
                  <div
                    style={{ fontSize: '14px', marginTop: '-19px', paddingLeft: '16px' }}
                    className="container-table-row">
                    <Grid>
                      <React.Fragment>
                        {t(i18nKeys.events.eventDetails.messages.testEvent)}
                        <div style={{ paddingBottom: '10px', marginTop: '-9px' }}>
                          <Button
                            onClick={() => {
                              void (async () => {
                                try {
                                  setIsCreatingTestEvent(true);
                                  await handleCreateTestEvent();
                                  setIsCreatingTestEvent(false);
                                } catch (error) {
                                  setIsCreatingTestEvent(false);
                                }
                              })();
                            }}
                            disabled={isCreatingTestEvent}>
                            {isCreatingTestEvent
                              ? t(i18nKeys.events.eventDetails.buttons.creatingTestEvent)
                              : t(i18nKeys.events.eventDetails.buttons.createTestEvent)}
                          </Button>
                        </div>
                      </React.Fragment>
                    </Grid>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </ExpandableSection>
      )}
    </React.Fragment>
  );
};

export default Access;
