/**
 * NOTE: All custom environment variables must have the prefix `REACT_APP_`.
 * See https://create-react-app.dev/docs/adding-custom-environment-variables/.
 *
 */
export interface Env {
  /* API gateway origin */
  readonly REACT_APP_API_ORIGIN: string;
  readonly REACT_APP_CHECKOUT_GRAPHQL_API_ORIGIN?: string;

  /* Cognito */
  readonly REACT_APP_GANDALF_COGNITO_CLIENT_ID: string;
  readonly REACT_APP_GANDALF_COGNITO_USER_POOL_ID: string;
  readonly REACT_APP_COGNITO_GANDALF_IDP_NAME: string;
  readonly REACT_APP_COGNITO_AMAZON_FEDERATE_IDP_NAME: string;
  readonly REACT_APP_GANDALF_COGNITO_APP_WEB_DOMAIN: string;
  readonly REACT_APP_GANDALF_LOGIN_WEB_DOMAIN: string;
  readonly REACT_APP_GANDALF_WEB_DOMAIN: string;
  readonly REACT_APP_GANDALF_REGION: string;

  readonly REACT_APP_GAME_UI_DOMAIN: string;

  /* For displaying a banner message across the top of the app */
  readonly REACT_APP_WARNING_BANNER_MESSAGE?: string;

  /**
   * Name of the challenge resources bucket for this stage.
   */
  readonly REACT_APP_CHALLENGE_RESOURCES_BUCKET_NAME: string;

  /**
   * Name of the stage in which this react app is deployed.
   *
   * @example dev, staging, prod
   */
  readonly REACT_APP_STAGE_NAME: string;

  /**
   * Whether to build the console for a side-by-side console deployment.
   *
   * A value of `true` means "use the hybrid experience";
   * any other value means "do not use the hybrid experience".
   */
  readonly REACT_APP_USE_HYBRID_EXPERIENCE: string;

  /**
   * Whether the usage plan feature is enabled
   */
  readonly REACT_APP_IS_USAGE_PLAN_ENABLED: string;

  /**
   * Whether the challenge review automation feature is enabled
   */
  readonly REACT_APP_IS_CHALLENGE_REVIEW_ENABLED: string;

  /**
   * Whether the Event Template feature should be enabled
   */
  readonly REACT_APP_IS_EVENT_TEMPLATE_ENABLED: string;
  /*
   * Whether the challenge review automation feature is enabled
   */
  readonly REACT_APP_IS_NEW_TOP_NAVIGATION_ENABLED: string;

  /*
   * Whether we should redirect unverified emails to Gandalf OTP verification
   */
  readonly REACT_APP_GANDALF_OTP_REDIRECT_ENABLED: string;
  /*
   * checkout url
   */
  readonly REACT_APP_CHECKOUT_URL: string;
  /*
   * billing purchase history url
   */
  readonly REACT_APP_BILLING_PURCHASE_HISTORY_URL?: string;
  /*
   * rum guestRoleArn
   */
  readonly REACT_APP_RUM_GUEST_ROLE_ARN?: string;
  /*
   * rum identity pool id
   */
  readonly REACT_APP_RUM_IDENTITY_POOL_ID?: string;
  /*
   * rum application id
   */
  readonly REACT_APP_RUM_APPLICATION_ID?: string;
  /*
   * rum application id
   */
  readonly REACT_APP_RUM_APPLICATION_REGION?: string;
  /*
   * run application feature flag
   */
  readonly REACT_APP_SHOW_ADMIN_PAGES?: "true" | "false";
}

export type EnvVarName = keyof Env;

/**
 * Get an environment variable as a string.
 *
 * @param variableName
 * @param defaultValue
 */
export const getEnvVar = (variableName: EnvVarName, defaultValue?: string): string => {
  const variable = process.env[variableName];
  if (!variable) {
    if (defaultValue != null) {
      return defaultValue;
    }
    throw new Error(`${variableName} environment variable must be defined`);
  }
  return variable;
};

/**
 * Get an environment variable as an integer.
 *
 * @param variableName
 * @param defaultValue
 */
export const getEnvVarAsInt = (variableName: EnvVarName, defaultValue?: number): number => {
  const envVarStr: string = getEnvVar(variableName, defaultValue != null ? defaultValue.toString() : undefined);
  return parseInt(envVarStr, 10);
};

/**
 * Set/delete an environment variable.
 *
 * @param variableName
 * @param value - The value for the environment variable.
 * If undefined, the environment variable is deleted.
 *
 */
export const setEnvVar = (variableName: EnvVarName, value: string | undefined): void => {
  if (value == null) {
    delete process.env[variableName];
  } else {
    process.env[variableName] = value;
  }
};
