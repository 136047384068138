import {
  Box,
  Button,
  ButtonDropdown,
  Container,
  FileUpload,
  FormField,
  Header,
  Icon,
  Input,
  Link,
  Pagination,
  Popover,
  SpaceBetween,
  Table,
  TextFilter,
  Grid,
  TokenGroup,
} from '@amzn/awsui-components-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../store/api.context';
import { Event, EventParticipantsFields } from '../../../types/Event';
import { i18nKeys } from '../../../utils/i18n.utils';
import { ConfirmModal } from '../ConfirmModal';
import { eventParticipantValidator } from '@/src/utils/event.validation.utils';
import { isValidEmail } from '@/src/utils/validation.utils';
import { useEvents } from '@/src/store/events.context';
import Papa from 'papaparse';
import _ from 'lodash';
import { useFlashbars } from '@/src/store/flashbar.context';
import { errorFlashbar } from '@/src/utils/notification.utils';
import { useUser } from '@/src/store/user.context';

interface ParticipantsProps {
  target: Event;
  handleCancelOrDeleteEvent: () => void;
  eventId: string;
}

interface ItokenUser {
  label: string;
}

export const Participants: React.FC<ParticipantsProps> = ({ handleCancelOrDeleteEvent, eventId }) => {
  const { t } = useTranslation();
  const { inviteParticipantModal, event, toggleInviteParticipantModal } = useEvents();
  const { addFlashbar, clearFlashbars } = useFlashbars();
  const { emailParticipantsAPI } = useApi();
  const { user } = useUser();
  const [participantEmail, setParticipantEmail] = useState('');
  const [csvFile, setCSVFile] = useState<File[] | undefined>([]);
  const [participantEmailError, setParticipantEmailError] = useState('');
  const [participantCsvFileError, setParticipantCsvFileError] = useState('');
  const [confirmMultiResendInvitationVisible, setConfirmMultiResendInvitationVisible] = useState(false);
  const [tselectedItems, settSelectedItems] = React.useState<{ name: string }[]>([]);
  const [participantList, setParticipantList] = useState<{ name: string }[]>([]);
  const [filterText, setFilterText] = useState('');
  const [loadingTable, setLoadingTable] = useState(false);
  const [removeParticipantModal, setRemoveParticipantModal] = useState(false);
  const [addParticipantModal, setAddParticipantModal] = useState(false);
  const [csvInviteModal, setCsvInviteModal] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [pageToken, setPageToken] = useState({});
  const [currPageIndex, setCurrPageIndex] = useState(1);
  const [tempLastToken, setTempLastToken] = useState<string>('');
  const [csvFormData, setCsvFormData] = useState();
  const [csvUploadedEmailList, setCsvUploadedEmailList] = useState<string[]>([]);
  const [tempUserEmail, setTempUserEmail] = useState<ItokenUser[]>([]);
  const [loading, setLoading] = useState(false);

  const pageSize = 20;

  const handleMultiReInviteParticipants = async () => {
    if (tselectedItems?.length > 0) {
      setConfirmMultiResendInvitationVisible(false);
      const participantEmailAsString = tselectedItems?.map((item) => (item?.name ? item?.name : '')) || [];
      await emailParticipantsAPI.createParticipant(eventId, {
        emails: participantEmailAsString,
      });
    }
  };

  // on pagination click userList updates
  const onPageClick = async (currentPageIndex: number) => {
    setCurrPageIndex(currentPageIndex);
    let res;
    // @ts-expect-error something
    if (Object.keys(pageToken).length !== 0 && pageToken[currentPageIndex] !== '') {
      // @ts-expect-error something
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      res = await emailParticipantsAPI.participantList(eventId, pageSize, pageToken[currentPageIndex]);
    } else if (currentPageIndex !== 1) {
      if (tempLastToken !== '') res = await emailParticipantsAPI.participantList(eventId, pageSize, tempLastToken);
    } else {
      res = await emailParticipantsAPI.participantList(eventId, pageSize);
    }
    if (res?.nextToken) {
      setPageToken({ ...pageToken, [currentPageIndex + 1]: res?.nextToken });
      setTempLastToken(res?.nextToken);
    }
    if (res?.participantsEmail) {
      const modifiedEmailList = res?.participantsEmail?.map((item) => {
        return { name: item };
      });
      setParticipantList(modifiedEmailList);
    }
  };

  const getParticipantList = async () => {
    setLoadingTable(true);
    const res = await emailParticipantsAPI.participantList(eventId, pageSize);
    const resCount = await emailParticipantsAPI.countParticipant(eventId);
    const numberOfPages = resCount ? Math.ceil(parseInt(resCount?.count, 10) / pageSize) : 1;
    setPageCount(numberOfPages);
    let tempPageToken = {};
    for (let i = 1; i < numberOfPages + 1; i++) {
      tempPageToken = { ...tempPageToken, [i]: '' };
    }
    if (res?.participantsEmail) {
      const modifiedEmailList = res?.participantsEmail?.map((item) => {
        return { name: item };
      });
      setParticipantList(modifiedEmailList);
      if (res?.nextToken) {
        setTempLastToken(res?.nextToken);
        tempPageToken = { ...tempPageToken, [currPageIndex + 1]: res?.nextToken };
      }
    }
    // set selected Items to empty array
    settSelectedItems([]);
    setLoadingTable(false);
    setPageToken(tempPageToken);
  };
  useEffect(() => {
    void getParticipantList();
  }, []);
  const validator = useMemo(
    () =>
      eventParticipantValidator(
        participantEmail,
        t(i18nKeys.settings.errors.invalidEmailFormat),
        new Map<EventParticipantsFields, (error: string) => void>([
          [EventParticipantsFields.PARTICIPANT, (error: string) => setParticipantEmailError(error)],
        ])
      ),
    [participantEmail, eventParticipantValidator]
  );

  const downloadTemplate = async () => {
    const res: Blob = await emailParticipantsAPI.getTemplateParticipant(eventId);
    const downloadFile = () => {
      const url = window.URL.createObjectURL(res);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'InviteParticipantTemplate.csv');
      document.body.appendChild(link);
      link.click();
      link.remove();
    };
    downloadFile();
  };

  const searchEmailFromServer = async (value: string) => {
    setFilterText(value);
    setLoadingTable(true);
    if (value) {
      const res: string[] = await emailParticipantsAPI.searchParticipant(eventId, value);
      if (res && res.length > 0) {
        const modifiedEmailList = res?.map((item) => {
          return { name: item };
        });
        setParticipantList(modifiedEmailList);
      }
    } else {
      void getParticipantList();
    }
    setLoadingTable(false);
  };

  /**
   *
   * @param files
   * handle csv upload and add participants
   */

  const handleCSVUpload = (files: File[] | undefined) => {
    if (handleMaxParticipantsValidation()) {
      return;
    }
    setParticipantCsvFileError('');
    if (files && files[0]) {
      const uploadedFile = files[0];
      const reader = new FileReader();
      const formData = new FormData();
      formData.append('name', 'InviteParticipantTemplate');
      formData.append('file', uploadedFile);

      reader.addEventListener('load', (e) => {
        if (e.target) {
          const csvData = e.target.result;
          let invalidCSV = false;
          const participantEmails: string[] = [];
          if (csvData) {
            Papa.parse(files[0], {
              complete: (results) => {
                const newData = _.flatMap(results.data);
                newData?.forEach((singleEmail, i) => {
                  if (i > 0) {
                    if (!isValidEmail(singleEmail as string)) {
                      invalidCSV = true;
                    }
                    // @ts-expect-error something
                    participantEmails.push(singleEmail);
                  }
                });
              },
            });
            if (!invalidCSV) {
              setCsvUploadedEmailList(participantEmails);
              // @ts-expect-error something
              setCsvFormData(formData);
            } else {
              setParticipantCsvFileError(t(i18nKeys.participants.addParticipants.importCSV.uploadFailed));
              return;
            }
          }
        }
      });
      reader.readAsBinaryString(uploadedFile);
      setCSVFile(files);
    }
  };

  const sendCsvToSever = async () => {
    setLoading(true);
    setCsvInviteModal(false);
    await emailParticipantsAPI.inviteUploadParticipant(eventId, csvFormData);
    setCSVFile([]);
    void getParticipantList();
    setLoading(false);
  };

  const handleRemoveParticipants = async () => {
    setRemoveParticipantModal(false);
    const newData = participantList?.filter(
      ({ name: email1 }) => !tselectedItems?.some(({ name: email2 }) => email1 === email2)
    );
    setParticipantList(newData);
    const participantEmailAsString = tselectedItems?.map((item) => (item?.name ? item?.name : '')) || [];
    await emailParticipantsAPI.removeParticipants(eventId, {
      emails: participantEmailAsString,
    });
    void getParticipantList();
  };

  const sendEmailToParticipant = async () => {
    try {
      setAddParticipantModal(false);
      setLoading(true);
      const data = [...tempUserEmail];
      const useEmailAsListOfArr = data?.map((item) => item.label);
      await emailParticipantsAPI.createParticipant(eventId, {
        emails: useEmailAsListOfArr,
      });
      setTempUserEmail([]);
      void getParticipantList();
    } catch (e) {
      // error showed by API
    }
    setLoading(false);
  };

  const handleAddParticipants = () => {
    if (validator.isValidSection(true)) {
      if (handleMaxParticipantsValidation()) {
        setParticipantEmail('');
        return;
      }
      setTempUserEmail([{ label: participantEmail }, ...tempUserEmail]);
      setParticipantEmail('');
    }
  };

  const handleMaxParticipantsValidation = () => {
    if (
      event?.maxExpectedParticipants &&
      participantList.length + tempUserEmail.length >= event?.maxExpectedParticipants
    ) {
      clearFlashbars();
      addFlashbar(
        errorFlashbar(t(i18nKeys.participants.addParticipants.error, { participants: participantList.length }), '')
      );
      return true;
    }
  };

  /**
   * handle dropdown action click
   */

  const handleActionClick = (clickEvent: any) => {
    if (clickEvent.detail.id === 'reasendInvite') {
      setConfirmMultiResendInvitationVisible(true);
    }
  };

  return (
    <React.Fragment>
      <SpaceBetween direction="vertical" size="s">
        <Container header={<Header variant="h1">{t(i18nKeys.participants.title)}</Header>}>
          <Grid gridDefinition={[{ colspan: 5 }, { colspan: 1 }, { colspan: 6 }]}>
            <div>
              <form
                noValidate
                onSubmit={(e) => {
                  e.preventDefault();
                  handleAddParticipants();
                }}>
                <SpaceBetween size="m" direction="vertical">
                  <Box>
                    <FormField
                      errorText={participantEmailError}
                      description={user?.isSubscribedUser ? t(i18nKeys.participants.addParticipants.caption) : ''}
                      label={t(i18nKeys.participants.addParticipants.title)}>
                      <Input
                        value={participantEmail}
                        type="email"
                        placeholder="alias@domain.com"
                        onChange={({ detail }) => setParticipantEmail(detail.value)}
                        onBlur={() => participantEmail && validator.isValidField(EventParticipantsFields.PARTICIPANT)}
                      />
                    </FormField>
                    <Box margin={{ top: 's' }}>
                      <SpaceBetween direction="horizontal" size="xs">
                        <Button formAction="submit">{t(i18nKeys.general.add)}</Button>
                        <Button
                          formAction="none"
                          loading={loading}
                          variant="primary"
                          disabled={!(tempUserEmail.length > 0)}
                          onClick={() => tempUserEmail?.length > 0 && setAddParticipantModal(true)}>
                          {`${t(i18nKeys.participants.buttons.saveEmailInviteOne)} ${
                            tempUserEmail?.length > 0 ? tempUserEmail?.length : ''
                          } ${t(i18nKeys.participants.buttons.saveEmailInviteTwo)}`}
                        </Button>
                      </SpaceBetween>
                    </Box>
                    <Box>
                      <TokenGroup
                        onDismiss={({ detail: { itemIndex } }) => {
                          setTempUserEmail([
                            ...tempUserEmail.slice(0, itemIndex),
                            ...tempUserEmail.slice(itemIndex + 1),
                          ]);
                        }}
                        items={tempUserEmail}
                        limit={4}
                      />
                    </Box>
                  </Box>
                </SpaceBetween>
              </form>
            </div>
            <div style={{ justifyContent: 'center', display: 'flex', marginTop: '25px' }}>
              <Box>{t(i18nKeys.participants.addParticipants.or)}</Box>
            </div>
            <div>
              <FormField
                label={t(i18nKeys.participants.addParticipants.importCSV.title)}
                description={t(i18nKeys.participants.addParticipants.importCSV.description)}
                errorText={participantCsvFileError}
                constraintText={participantCsvFileError && t(i18nKeys.participants.addParticipants.importCSV.hint)}>
                <SpaceBetween direction="horizontal" size="l">
                  <FileUpload
                    onChange={({ detail }) => void handleCSVUpload(detail.value)}
                    accept="text/csv"
                    value={csvFile || []}
                    showFileLastModified
                    showFileSize
                    tokenLimit={3}
                    i18nStrings={{
                      uploadButtonText: (e) =>
                        e
                          ? t(i18nKeys.participants.addParticipants.importCSV.chooseFiles)
                          : t(i18nKeys.participants.addParticipants.importCSV.chooseFile),
                      dropzoneText: (e) =>
                        e
                          ? t(i18nKeys.participants.addParticipants.importCSV.dropFilesToUpload)
                          : t(i18nKeys.participants.addParticipants.importCSV.dropFileToUpload),
                      removeFileAriaLabel: (e) =>
                        `${t(i18nKeys.participants.addParticipants.importCSV.removeFile)}  ${e + 1}`,
                      limitShowFewer: t(i18nKeys.participants.addParticipants.importCSV.showFewerFiles),
                      limitShowMore: t(i18nKeys.participants.addParticipants.importCSV.showMoreFiles),
                      errorIconAriaLabel: t(i18nKeys.participants.addParticipants.importCSV.error),
                    }}
                  />
                  <Box>
                    <Button variant="link" onClick={() => void downloadTemplate()}>
                      <Box variant="span" padding={{ right: 'xs' }} color="inherit">
                        <Icon name="download" />
                      </Box>
                      {t(i18nKeys.participants.addParticipants.downloadTemplate)}
                    </Button>
                  </Box>
                </SpaceBetween>
                {csvFile && csvFile?.length > 0 && (
                  <div style={{ marginTop: '10px' }}>
                    <Button
                      loading={loading}
                      variant="primary"
                      ariaLabel={t(i18nKeys.participants.buttons.inviteCsvBtn)}
                      onClick={() => setCsvInviteModal(true)}>
                      {t(i18nKeys.participants.buttons.inviteCsvBtn)}
                    </Button>
                  </div>
                )}
              </FormField>
            </div>
          </Grid>
        </Container>
        {/* table container */}
        <Container>
          <Header
            variant="h1"
            counter={`(${tselectedItems && tselectedItems.length > 0 ? `${tselectedItems.length}/` : ''}${
              participantList ? participantList.length : 0
            })`}
            info={
              <Popover
                triggerType="custom"
                content={
                  <SpaceBetween direction="vertical" size="s">
                    {t(i18nKeys.participants.headers.info.title)}
                    <Link onFollow={handleCancelOrDeleteEvent}>{t(i18nKeys.participants.headers.info.cancelNow)}</Link>
                  </SpaceBetween>
                }>
                <Link>{t(i18nKeys.general.info)}</Link>
              </Popover>
            }
            // remove and action buttons
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  disabled={!tselectedItems || tselectedItems?.length === 0}
                  onClick={() => setRemoveParticipantModal(true)}>
                  {t(i18nKeys.general.remove)}
                </Button>
                <ButtonDropdown
                  disabled={!tselectedItems || tselectedItems?.length === 0}
                  className="mr-5"
                  items={[{ text: 'Resend Invite', id: 'reasendInvite', disabled: false }]}
                  onItemClick={handleActionClick}>
                  {t(i18nKeys.events.eventDetails.buttons.actionsPlaceholder)}
                </ButtonDropdown>
              </SpaceBetween>
            }>
            {t(i18nKeys.participants.headers.participants)}
          </Header>
          <Table
            loading={loadingTable}
            variant="borderless"
            onSelectionChange={({ detail }) => settSelectedItems(detail.selectedItems)}
            selectedItems={tselectedItems}
            filter={
              <SpaceBetween direction="horizontal" size="s">
                <TextFilter
                  className="text-filter"
                  filteringPlaceholder={t(i18nKeys.participants.labels.searchParticipants)}
                  filteringAriaLabel={t(i18nKeys.participants.labels.userFilteringLabel)}
                  filteringText={filterText}
                  onChange={({ detail }) => void searchEmailFromServer(detail.filteringText)}
                  disabled={participantList?.length === 0}
                />
              </SpaceBetween>
            }
            columnDefinitions={[
              {
                id: 'variable',
                header: 'Participant',
                cell: (item) => <Link href="#">{item.name}</Link>,
                isRowHeader: true,
              },
            ]}
            columnDisplay={[
              { id: 'variable', visible: true },
              { id: 'value', visible: true },
            ]}
            items={participantList}
            loadingText="Loading resources"
            selectionType="multi"
            trackBy="name"
            empty={
              participantList?.length === 0 && (
                <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
                  <SpaceBetween size="m">
                    <b>{t(i18nKeys.participants.table.emptyView.title)}</b>
                  </SpaceBetween>
                </Box>
              )
            }
            pagination={
              <Pagination
                currentPageIndex={currPageIndex}
                onChange={({ detail }) => {
                  void onPageClick(detail.currentPageIndex);
                }}
                pagesCount={pageCount}
              />
            }
          />
        </Container>
      </SpaceBetween>
      {tselectedItems && (
        <>
          <ConfirmModal
            visible={confirmMultiResendInvitationVisible}
            message={`${t(i18nKeys.participants.messages.reInviteparticipant)} ${tselectedItems?.length} ${t(
              i18nKeys.participants.messages.participants
            )}`}
            confirmBtnLabel={t(i18nKeys.participants.buttons.confirmReInvitation)}
            onConfirm={() => handleMultiReInviteParticipants()}
            onCancel={() => setConfirmMultiResendInvitationVisible(false)}
          />
          <ConfirmModal
            visible={csvInviteModal}
            title={t(i18nKeys.participants.messages.sendInviteCallback)}
            message={`${t(i18nKeys.participants.messages.sendEmailInviteOne)} ${csvUploadedEmailList?.length} ${t(
              i18nKeys.participants.messages.sendEmailInviteTwo
            )} ${t(i18nKeys.participants.messages.sendEmailInviteThree)}`}
            cancelBtnLabel={t(i18nKeys.participants.buttons.goBackBtn)}
            confirmBtnLabel={`${t(i18nKeys.participants.buttons.saveEmailInviteOne)} ${
              csvUploadedEmailList?.length
            } ${t(i18nKeys.participants.buttons.saveEmailInviteTwo)}`}
            onConfirm={() => void sendCsvToSever()}
            onCancel={() => setCsvInviteModal(false)}
          />
          <ConfirmModal
            visible={removeParticipantModal}
            title="Save"
            message={`${t(i18nKeys.participants.messages.removeMsgOne)} ${tselectedItems?.length} ${t(
              i18nKeys.participants.messages.removeMsgTwo
            )}`}
            cancelBtnLabel={t(i18nKeys.participants.buttons.goBackBtn)}
            confirmBtnLabel={t(i18nKeys.participants.buttons.saveRemoveBtn)}
            onConfirm={() => void handleRemoveParticipants()}
            onCancel={() => setRemoveParticipantModal(false)}
          />
          <ConfirmModal
            visible={tselectedItems?.length > 0 && inviteParticipantModal}
            title={t(i18nKeys.participants.messages.sendInviteCallback)}
            message={`${t(i18nKeys.participants.messages.sendEmailInviteOne)} ${tselectedItems?.length} ${t(
              i18nKeys.participants.messages.sendEmailInviteTwo
            )} ${t(i18nKeys.participants.messages.sendEmailInviteThree)}`}
            cancelBtnLabel={t(i18nKeys.participants.buttons.goBackBtn)}
            confirmBtnLabel={`${t(i18nKeys.participants.buttons.saveEmailInviteOne)} ${tselectedItems?.length} ${t(
              i18nKeys.participants.buttons.saveEmailInviteTwo
            )}`}
            onConfirm={() => void sendEmailToParticipant()}
            onCancel={() => toggleInviteParticipantModal(false)}
          />
          <ConfirmModal
            visible={addParticipantModal}
            title={t(i18nKeys.participants.messages.sendInviteCallback)}
            message={`${t(i18nKeys.participants.messages.sendEmailInviteOne)} ${tempUserEmail?.length} ${t(
              i18nKeys.participants.messages.sendEmailInviteTwo
            )} ${t(i18nKeys.participants.messages.sendEmailInviteThree)}`}
            cancelBtnLabel={t(i18nKeys.participants.buttons.goBackBtn)}
            confirmBtnLabel={`${t(i18nKeys.participants.buttons.saveEmailInviteOne)} ${tempUserEmail?.length} ${t(
              i18nKeys.participants.buttons.saveEmailInviteTwo
            )}`}
            onConfirm={() => void sendEmailToParticipant()}
            onCancel={() => setAddParticipantModal(false)}
          />
        </>
      )}
    </React.Fragment>
  );
};
