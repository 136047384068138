import React from 'react';
import { Box, Button, ColumnLayout, Grid, GridProps, SpaceBetween } from '@amzn/awsui-components-react';
import './BookEventSteps.scss';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import { BOOK_EVENT_STEPS } from './book-event-steps.config';

interface BookEventStepsProps {
  onBrowseAllEvents: () => void;
}

const BookEventSteps: React.FC<BookEventStepsProps> = ({ onBrowseAllEvents }) => {
  const { t } = useTranslation();
  const colspan: GridProps.BreakpointMapping = { xl: 4, l: 4, m: 4, s: 4, xs: 12, xxs: 12 };
  return (
    <SpaceBetween size={'s'} alignItems="center" direction="vertical">
    <div className="bookevent-steps-container">
      <Box variant="h1" textAlign="center" color="inherit">
        {t(i18nKeys.catalog.bookEventSteps.title)}
      </Box>
      <ColumnLayout borders="horizontal" columns={1}>
        <div className="steps-containter">
          <Grid gridDefinition={[{ colspan }, { colspan }, { colspan }]}>
            {BOOK_EVENT_STEPS.map((step) => (
              <div key={step.title} className="step">
                <div className="step__item">
                  <div className="step__item__image">
                    <img src="assets/book-event-background.png" title={step.title} className={step.background} />
                    <img src={step.image} title={step.title} className={step.imageClass} />
                  </div>
                </div>
                <Box color="inherit" variant="h2" textAlign="left">
                  {t(step.title)}
                </Box>
                <Box color="inherit" data-classname="step__item__subtitle" textAlign="left" margin={{left: 'xxxs'}}>
                  {step.link && (
                    <Button variant="link" onClick={onBrowseAllEvents}>
                      {t(step.link)}
                    </Button>
                  )}
                  {t(step.subtitle)}
                </Box>
              </div>
            ))}
          </Grid>
        </div>
        <div className="bookevent-steps-container__browse_all">
          <Button onClick={onBrowseAllEvents}>{t(i18nKeys.catalog.browseAllEvents)}</Button>
        </div>
      </ColumnLayout>
    </div>
    </SpaceBetween>
  );
};

export default BookEventSteps;
