import React, { useEffect, useMemo } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  ContentLayout,
  Header,
  NonCancelableCustomEvent,
  Tabs,
  TabsProps,
} from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { useJamDashboard } from '@/src/store/jam-dashboard.context';
import { JamEventTypes } from '@/src/types/JamDashboard';
import { i18nKeys } from '@/src/utils/i18n.utils';
import JamList from '../../ui/molecules/MyJams/JamList/JamList';
import { useFlashbars } from '@/src/store/flashbar.context';
import JamSpinner from '@/src/components/common/JamSpinner';
import { RoutePath } from '@/src/RoutePath';
import { getErrorMessage } from '@/src/utils/errors.utils';
import { customEventTrigger } from '../../analytics/createEventTrigger';
import { useTheme } from '@/src/store/theme.context';
import { Mode, applyMode } from '@amzn/awsui-global-styles';
import { useHeader } from '@/src/store/header.context';

const tabs = {
  [JamEventTypes.PastEvents]: RoutePath.MY_JAMS_PAST,
  [JamEventTypes.ActiveEvents]: RoutePath.MY_JAMS_ACTIVE,
  [JamEventTypes.UpcomingEvents]: RoutePath.MY_JAMS_UPCOMING,
};

const MyJams = () => {
  const { t } = useTranslation();
  const { events, isFetchingEvents, getEventsByType } = useJamDashboard();
  const { addErrorFlashbar, clearFlashbars } = useFlashbars();
  const { themeMode } = useTheme();
  const { pathname } = useLocation();
  const history = useHistory();
  const { setShowThemeToggle } = useHeader();
  const isMyJamsPath = pathname.includes(RoutePath.MY_JAMS);

  useEffect(() => {
    applyMode(Mode.Dark) // directly update theme mode
    return () => {
      applyMode(themeMode)
    }
  }, [])

  useEffect(() => {
    setShowThemeToggle(false)
    return () => {
      setShowThemeToggle(true)
    }
  }, [])

  const currentTab = useMemo(() => {
    if (pathname.includes(RoutePath.MY_JAMS_PAST)) {
      return JamEventTypes.PastEvents;
    }
    if (pathname.includes(RoutePath.MY_JAMS_UPCOMING)) {
      return JamEventTypes.UpcomingEvents;
    }
    return JamEventTypes.ActiveEvents;
  }, [pathname]);

  const fetchEvents = async () => {
    try {
      clearFlashbars();
      await getEventsByType(currentTab);
    } catch (e) {
      addErrorFlashbar(
        getErrorMessage(e),
        // @ts-expect-error index issue we can ignore
        t(i18nKeys.myJams.eventTabs[currentTab as string])
      );
    }
  };

  useEffect(() => {
    if (isMyJamsPath) {
      return;
    }
    void fetchEvents();
  }, [currentTab, isMyJamsPath]);

  const getEventsList = () => {
    if (isFetchingEvents) {
      return (
        <Box>
          <JamSpinner />
        </Box>
      );
    }

    return <JamList events={events} eventType={currentTab} />;
  };

  if (isMyJamsPath) {
    return <Redirect to={RoutePath.MY_JAMS_ACTIVE} />;
  }

  const onTabChange = (tab: NonCancelableCustomEvent<TabsProps.ChangeDetail>) => {
    customEventTrigger('linkclick', tab.detail.activeTabId, window.location.href, tab.detail.activeTabId, {
      context: 'Tab Click',
    });
    history.push(tabs[tab.detail.activeTabId as keyof typeof tabs]);
  };

  const onJamJoinAnEventLinkClick = () => {
    customEventTrigger('click', 'Join An Event', window.location.href, 'Join An Event', {});
    history.push(RoutePath.JOIN_AN_EVENT);
  };

  return (
    <ContentLayout
      disableOverlap
      header={
        <Box margin={{ top: 'l' }}>
          <Header
            description={t(i18nKeys.myJams.header.caption, {
              count: events.length,
            })}
            actions={
              <Button variant="primary" onClick={onJamJoinAnEventLinkClick}>
                  {t(i18nKeys.myJams.header.joinAnEvent)}
              </Button>
            }>
            {t(i18nKeys.myJams.header.title)}
            <Box margin={{ bottom: 'l' }} />
          </Header>
        </Box>
      }>
      <Box padding={'m'}>
        <Tabs
          i18nStrings={{
            scrollLeftAriaLabel: t(i18nKeys.myJams.eventTabs.gotoPrevTab),
            scrollRightAriaLabel: t(i18nKeys.myJams.eventTabs.gotoNextTab),
          }}
          tabs={[
            {
              label: t(i18nKeys.myJams.eventTabs.activeEvents),
              id: JamEventTypes.ActiveEvents,
              content: getEventsList(),
            },
            {
              label: t(i18nKeys.myJams.eventTabs.upcomingEvents),
              id: JamEventTypes.UpcomingEvents,
              content: getEventsList(),
            },
            {
              label: t(i18nKeys.myJams.eventTabs.pastEvents),
              id: JamEventTypes.PastEvents,
              content: getEventsList(),
            },
          ]}
          activeTabId={currentTab}
          onChange={(tab) => {
            onTabChange(tab);
          }}
        />
      </Box>
    </ContentLayout>
  );
};

export default MyJams;
