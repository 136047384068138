import React, {
  useContext,
  useState,
  createContext,
  SetStateAction,
  Dispatch,
  useMemo,
  useCallback,
  useEffect,
  useRef,
  MutableRefObject,
} from 'react';
import { CollectionPreferencesProps, PropertyFilterProps } from '@amzn/awsui-components-react';

import { LoggingService } from '@/src/utils/logging-service.utils';
import { visibleContentForChallenge } from '@/src/components/event-templates/EventTemplateCreate/Sections/SelectChallenges/table-config';
import { ChallengeListItem, ChallengeStatus } from '../types/Challenge';
import { ChallengeSet } from '../types/ChallengeSet';
import { useChallenges } from './challenge.context';
import { EventTemplateChallengeTabId, useCreateEventTemplate } from './create-event-template.context';
import { useChallengeSet } from './challenge-set.context';
import { STABILITY_HEALTH_CRITERIA } from '../constants/event-template.constants';
import { useEditEventTemplate } from './edit-event-template.context';
import { useUser } from './user.context';

export interface EventTemplateChallengesContextValue {
  challengeListItems: ChallengeListItem[];
  selectedChallengeListItems: ChallengeListItem[];
  selectedEditChallengeListItems: ChallengeListItem[];
  challengeSets: ChallengeSet[];
  currentSelectedChallenge: ChallengeListItem | undefined;
  onCurrentSelectedChallengeChange: Dispatch<SetStateAction<ChallengeListItem | undefined>>;
  currentSelectedChallengeSet: ChallengeSet | undefined;
  onCurrentSelectedChallengeSetChange: Dispatch<SetStateAction<ChallengeSet | undefined>>;
  activeChallengeTabId: string;
  setActiveChallengeTabId: (id: string) => void;
  propertyFilterProps: PropertyFilterProps | undefined;
  setPropertyFilterProps: Dispatch<SetStateAction<PropertyFilterProps | undefined>>;
  allPagesItems: ChallengeListItem[] | ChallengeSet[] | undefined;
  setAllPagesItems: Dispatch<SetStateAction<ChallengeListItem[] | ChallengeSet[] | undefined>>;
  challengeToggleEnable: boolean;
  isChallengeToggleEnable: Dispatch<SetStateAction<boolean>>;
  challengeSetToggleEnable: boolean;
  isChallengeSetToggleEnable: Dispatch<SetStateAction<boolean>>;
  eventTemplateChallengeOrSetPreferences: CollectionPreferencesProps.Preferences;
  setEventTemplateChallengeOrSetPreferences: Dispatch<CollectionPreferencesProps.Preferences>;
  filtertedEventTemplateChallengeOrSetCount: number;
  setFiltertedEventTemplateChallengeOrSetCount: Dispatch<SetStateAction<number>>;
  fetchChallenges: () => Promise<void>;
  fetchChallengeSets: () => Promise<void>;
  challengeTitles: { [challengeId: string]: string };
  challengeListItemMap: { [id: string]: ChallengeListItem };
  propertyFilterRef: MutableRefObject<HTMLDivElement | null>;
  paginationRef: MutableRefObject<HTMLDivElement | null>;
  collectionPrefRef: MutableRefObject<HTMLDivElement | null>;
}

const DEFAULT_VALUE: EventTemplateChallengesContextValue = {
  activeChallengeTabId: EventTemplateChallengeTabId.CHALLENGE,
  setActiveChallengeTabId: (_id: string) => {
    // do nothing
  },
  challengeListItems: [],
  selectedChallengeListItems: [],
  selectedEditChallengeListItems: [],
  currentSelectedChallenge: undefined,
  onCurrentSelectedChallengeChange: () => {
    // do nothing
  },
  challengeSets: [],
  currentSelectedChallengeSet: undefined,
  onCurrentSelectedChallengeSetChange: () => {
    // do nothing
  },
  propertyFilterProps: undefined,
  setPropertyFilterProps: () => {
    LoggingService.debug(`CreateEventContext: setPropertyFilterProps()`);
  },
  allPagesItems: undefined,
  setAllPagesItems: () => {
    LoggingService.debug(`CreateEventContext: setAllChallenges()`);
  },
  challengeToggleEnable: true,
  isChallengeToggleEnable: () => {
    // do nothing
  },
  challengeSetToggleEnable: true,
  isChallengeSetToggleEnable: () => {
    // do nothing
  },
  filtertedEventTemplateChallengeOrSetCount: 0,
  setFiltertedEventTemplateChallengeOrSetCount: () => {
    // do nothing
  },
  eventTemplateChallengeOrSetPreferences: {
    pageSize: 10,
    visibleContent: visibleContentForChallenge,
  },
  setEventTemplateChallengeOrSetPreferences: () => {
    // do nothing
  },
  fetchChallenges: async () => {
    // do nothing
  },
  fetchChallengeSets: async () => {
    // do nothing
  },
  challengeTitles: {},
  challengeListItemMap: {},
  propertyFilterRef: { current: null },
  paginationRef: { current: null },
  collectionPrefRef: { current: null },
};

const EventTemplateChallengesContext = createContext<EventTemplateChallengesContextValue>(DEFAULT_VALUE);

const EventTemplateChallengesProvider: React.FC = ({ children }) => {
  const { user } = useUser();
  const {
    challengeListItems,
    getChallenges,
    challengeTitles,
    challengeListItemMap,
    challengeWrapperMap,
    challengeListOpenSearchItems,
  } = useChallenges();
  const { challengeSets, getChallengeSets } = useChallengeSet();
  const { selectedChallenges } = useCreateEventTemplate();
  const { selectedChallenges: selectedEditChallenges } = useEditEventTemplate();

  const [currentSelectedChallenge, onCurrentSelectedChallengeChange] = useState<ChallengeListItem | undefined>();
  const [challengeToggleEnable, isChallengeToggleEnable] = useState<boolean>(true);
  const [propertyFilterProps, setPropertyFilterProps] = useState<PropertyFilterProps>();
  const [allPagesItems, setAllPagesItems] = useState<ChallengeListItem[] | ChallengeSet[]>();

  const [currentSelectedChallengeSet, onCurrentSelectedChallengeSetChange] = useState<ChallengeSet | undefined>(
    undefined
  );
  const [challengeSetToggleEnable, isChallengeSetToggleEnable] = useState<boolean>(false);

  const [activeChallengeTabId, setActiveChallengeTabId] = useState<string>(EventTemplateChallengeTabId.CHALLENGE);
  const [filtertedEventTemplateChallengeOrSetCount, setFiltertedEventTemplateChallengeOrSetCount] = useState<number>(0);
  const [eventTemplateChallengeOrSetPreferences, setEventTemplateChallengeOrSetPreferences] =
    useState<CollectionPreferencesProps.Preferences>({
      pageSize: 10,
      visibleContent: visibleContentForChallenge,
    });

  const [fetchingChallenges, setFetchingChallenges] = useState(false);
  const [fetchingChallengeSets, setFetchingChallengeSets] = useState(false);

  const propertyFilterRef = useRef<HTMLDivElement>(null);
  const paginationRef = useRef<HTMLDivElement>(null);
  const collectionPrefRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let newChallengeSets: ChallengeSet[] | undefined = [];
    if (currentSelectedChallengeSet) {
      newChallengeSets = challengeSets?.map((challengeSet) => {
        if (challengeSet.id === currentSelectedChallenge?.id) {
          challengeSet.isSelected = !challengeSet.isSelected;
        } else {
          challengeSet.isSelected = false;
        }
        return challengeSet;
      });
    } else {
      // This eslint we had to disable because as we are using newChallengeSets variable multiple places but still it's showing error seems like its incorrect error.
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      newChallengeSets = challengeSets?.map((challengeSet) => {
        challengeSet.isSelected = false;
        return challengeSet;
      });
    }
  }, [currentSelectedChallengeSet]);

  const fetchChallengeSets = useCallback(async () => {
    if (fetchingChallengeSets) return;
    setFetchingChallengeSets(true);
    await getChallengeSets();
  }, [getChallengeSets, fetchingChallengeSets]);

  const fetchChallenges = useCallback(async () => {
    if (fetchingChallenges) return;
    setFetchingChallenges(true);
    await getChallenges(false, false, true);
    setFetchingChallenges(false);
  }, [getChallenges, fetchingChallenges]);

  const selectedChallengeListItems = useMemo(() => {
    if (!challengeListOpenSearchItems || challengeListOpenSearchItems?.length === 0) return [];
    const selectedOpenSearchItems = selectedChallenges.map((_) =>
      challengeListOpenSearchItems.find((item) => _.id === item.challengeId)
    ) as ChallengeListItem[];
    return selectedOpenSearchItems.filter((challenge) => !!challenge);
  }, [challengeListOpenSearchItems, selectedChallenges]);

  const selectedEditChallengeListItems = useMemo(() => {
    return selectedEditChallenges
  }, [selectedEditChallenges]);

  const isChallengeAvailable = useCallback(
    (challenge: ChallengeListItem) => {
      return (
        challenge.status === ChallengeStatus.APPROVED &&
        (user?.isSuperAdmin ||
          user?.isEventAdmin ||
          (challenge.challengeId && !(challengeWrapperMap[challenge.challengeId] || challenge).isPrivate)) &&
        challenge.challengeId &&
        challengeWrapperMap[challenge.challengeId] &&
        (challengeWrapperMap[challenge.challengeId] || challenge).isEligibleForEvents()
      );
    },
    [challengeWrapperMap, user]
  );

  const filteredChallengeListItems = useMemo(() => {
    if (!challengeListItems || Object.keys(challengeWrapperMap).length === 0) {
      return [];
    }
    if (challengeToggleEnable) {
      const stableChallengeListItems = challengeListItems.filter(
        (challengeListItem) =>
          isChallengeAvailable(challengeListItem) &&
          challengeListItem.stability !== null &&
          challengeListItem.stability > STABILITY_HEALTH_CRITERIA &&
          !challengeListItem.challengeId?.includes('test')
      );
      return stableChallengeListItems;
    }
    return challengeListItems.filter(isChallengeAvailable);
  }, [challengeToggleEnable, challengeListItems, challengeWrapperMap]);

  const filteredChallengeSetsItems = useMemo(() => {
    if (!challengeSets) {
      return [];
    }
    if (challengeSetToggleEnable && Object.keys(challengeListItemMap)) {
      const stableChallengeListItems = challengeSets.filter((challengeSet) =>
        challengeSet.healthStatus(challengeListItemMap)
      );
      return stableChallengeListItems;
    }
    return challengeSets;
  }, [challengeSetToggleEnable, challengeSets, challengeListItemMap]);

  const data: EventTemplateChallengesContextValue = {
    challengeListItems: filteredChallengeListItems ?? [],
    challengeSets: filteredChallengeSetsItems ?? [],
    selectedChallengeListItems: selectedChallengeListItems ?? [],
    selectedEditChallengeListItems: selectedEditChallengeListItems ?? [],
    currentSelectedChallenge,
    onCurrentSelectedChallengeChange,
    currentSelectedChallengeSet,
    onCurrentSelectedChallengeSetChange,
    activeChallengeTabId,
    setActiveChallengeTabId,
    propertyFilterProps,
    setPropertyFilterProps,
    allPagesItems,
    setAllPagesItems,
    challengeToggleEnable,
    isChallengeToggleEnable,
    challengeSetToggleEnable,
    isChallengeSetToggleEnable,
    eventTemplateChallengeOrSetPreferences,
    setEventTemplateChallengeOrSetPreferences,
    filtertedEventTemplateChallengeOrSetCount,
    setFiltertedEventTemplateChallengeOrSetCount,
    fetchChallenges,
    fetchChallengeSets,
    challengeTitles,
    challengeListItemMap,
    propertyFilterRef,
    paginationRef,
    collectionPrefRef,
  };

  return <EventTemplateChallengesContext.Provider value={data}>{children}</EventTemplateChallengesContext.Provider>;
};

const useEventTemplateChallenges = () => {
  const context = useContext(EventTemplateChallengesContext);
  if (context === undefined) {
    throw new Error('useEventTemplateChallenges can only be used inside EventTemplateChallengesProvider');
  }
  return context;
};

export { EventTemplateChallengesProvider, useEventTemplateChallenges };
