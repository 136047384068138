import React, { useContext, useState } from 'react';
import { IdTokenParsed, User } from '../types/User';
import { useComponentDidMountEffect } from '../hooks/useComponentDidMountEffect';
import { useAuth } from './auth.context';
import { useApi } from './api.context';
import { Nullable } from '@/src/types/common';
import { IdentityProvider } from '@/src/types/IdentityProvider';
import { JamProfile } from '@/src/types/JamProfile';
import { preProdLogger } from '../utils/log.utils';

export interface UserContextValue {
  user: User | null;
  profile?: JamProfile;
  setUser: React.Dispatch<React.SetStateAction<User | null>> | null; // Exported for use by role spoofing component
  onProfileChange: (updatedProfile: JamProfile) => void
  isLoggedIn: boolean;
  loading: boolean;
}

// eslint-disable-next-line no-shadow
export enum UserPreferenceKeys {
  PAGE_SIZE = 'userPreferencesPageSize',
  DISPLAY_TIME = 'userPreferencesSecondaryDisplayTime',
}

/**
 * Parse cognito JWT token into JSON object
 *
 * @param token
 * @public
 */
const parseToken = (token: string): IdTokenParsed => {
  const payload = token.split('.')[1];
  const tokenParsed = atob(payload);
  return JSON.parse(tokenParsed) as IdTokenParsed;
};

const UserContext = React.createContext<UserContextValue>({
  user: null,
  profile: undefined,
  setUser: null,
  isLoggedIn: false,
  loading: true,
  onProfileChange: () => {
     // do nothing
  },
});

/**
 * Properties used to create a User.
 */
export interface DynamoDBUser {
  readonly name: string;
  readonly email: string;
  readonly providerName: Nullable<IdentityProvider>;
  readonly login: string;
  readonly roles: string[];
}

const UserProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [profile, setProfile] = useState<JamProfile>();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const { authClient } = useAuth();
  const { userApi, jamProfileAPI } = useApi();

  // load the user one time
  useComponentDidMountEffect(async () => {
    const idToken = await authClient.getIdToken();
    if (!idToken){
      setLoading(false);
      return;
    }
    parseToken(idToken || '');

    const userFromToken = User.fromIdToken(idToken)
    // call get user API
    try {
      const p1 = userApi.fetchUserByEmail(userFromToken.email)
      const p2 = jamProfileAPI.getProfile()
      const [userRes, profileRes] = await Promise.all([p1, p2]) as [DynamoDBUser, JamProfile]

      const dbUser = new User({
        name: userRes.name || userFromToken.name,
        email: userRes.email,
        identityProvider: userRes.providerName,
        username: userRes.login,
        groups: userRes.roles,
      });

      setProfile(profileRes)
      setUser(dbUser);
      setIsLoggedIn(userRes != null);
    } catch (err) {
      preProdLogger(err)
    } finally {
      setLoading(false);
    }
  });

  const onProfileChange = (updatedProfile: JamProfile) => {
    setProfile(updatedProfile)
  }
  const data: UserContextValue = { user, profile, setUser, isLoggedIn, loading, onProfileChange };

  return <UserContext.Provider value={data}>{children}</UserContext.Provider>;
};

const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser can only be used inside UserProvider');
  }
  return context;
};

export { UserProvider, useUser };
