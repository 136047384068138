import React, { useEffect, useState } from 'react';
import { Box, ColumnLayout, Container, Icon, SpaceBetween } from '@amzn/awsui-components-react';
import { sum } from 'lodash';
import moment from 'moment';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import { IJamChallenge } from '@/src/types/JamChallenges';
import { useJamChallenge } from '@/src/store/jam-challenge.context';
import Link from '../../../atoms/Link/Link';
import { JAM_EVENT_DETAILS_ROUTES } from '@/src/routes';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';
interface IGlobalInfo {
  totalPoint: number;
  eventEndDate?: string;
  solvedChallenges: any;
}

const ChallengeGlobalInfo: React.FC = () => {
  const { t } = useTranslation();
  const { jamChallengeData } = useJamChallenge();
  const { eventSlug, event } = useJamEventDetails();
  const [timeLeft, setTimeLeft] = useState<string>('');
  const [globalInfo, setGlobalInfo] = useState<IGlobalInfo>({
    totalPoint: 0,
    eventEndDate: '',
    solvedChallenges: [],
  });

  const addLeadingZero = (value: number) => (value < 10 ? `0${value}` : value);
  const isGamified = event?.gamified;
  const isCampaign = event?.type === "CAMPAIGN_GROUP"
  const showLeaderboard = !isCampaign || (isCampaign && isGamified);
  

  useEffect(() => {
    const TotalPoint = sum(jamChallengeData?.challenges?.map((challenge: IJamChallenge) => challenge.earnedPoints));
    const endDate = moment(jamChallengeData?.eventEndDate);

    const SolvedChallenges = jamChallengeData?.challenges?.filter((challenge: IJamChallenge) => challenge.solved);
    const interval = setInterval(() => {
      const now = moment();
      const duration = moment.duration(endDate.diff(now));
      const TimeLeft =
        (duration.days() || duration.hours() || duration.minutes()) > 0
          ? `${addLeadingZero(duration.days())}:${addLeadingZero(duration.hours())}:${addLeadingZero(
              duration.minutes()
            )}:${addLeadingZero(duration.seconds())}`
          : t(i18nKeys.myJams.challenges.header.timeExpired);
      setTimeLeft(TimeLeft);
      if (duration.days() === 0 && duration.hours() === 0 && duration.minutes() === 0) {
        clearInterval(interval);
      }
    }, 1000);

    setGlobalInfo({ totalPoint: TotalPoint, solvedChallenges: SolvedChallenges });

    return () => {
      setGlobalInfo({
        totalPoint: 0,
        eventEndDate: '',
        solvedChallenges: [],
      });
    }
  }, [jamChallengeData]);

  return (
    <Container variant="default" className="dark-bg">
      <ColumnLayout 
        columns={
          showLeaderboard ? 4 : 3
        } 
        variant="text-grid"
      >
        <SpaceBetween size="xxxs" direction="vertical">
          <Box>{t(i18nKeys.myJams.challenges.header.eventEndDate)}</Box>
          <Box variant="h4" className="global-value">
            {timeLeft}
          </Box>
        </SpaceBetween>
        <SpaceBetween size="xxxs" direction="vertical">
          <Box>{t(i18nKeys.myJams.challenges.header.challengesSolved)}</Box>
          <Box variant="h4" className="global-value">
            {globalInfo.solvedChallenges?.length > 0 ? globalInfo.solvedChallenges?.length : 0}/
            {jamChallengeData?.challenges?.length ?? 0}
          </Box>
        </SpaceBetween>
        <SpaceBetween size="xxxs" direction="vertical">
          <Box>{t(i18nKeys.myJams.challenges.header.point)}</Box>
          <Box variant="h4" className="global-value">
            {globalInfo.totalPoint}
          </Box>
        </SpaceBetween>
        {
          showLeaderboard ? (
            <SpaceBetween size="xxxs" direction="vertical">
            <Box>{t(i18nKeys.myJams.challenges.header.currentRank)}</Box>
            <Box margin={{ top: 'xxs' }}>
              <Link href={JAM_EVENT_DETAILS_ROUTES.LeaderBoard.resolve(eventSlug)}>
                {t(i18nKeys.myJams.challenges.details.overview.goToLeaderboard)}{' '}
                <Icon name="angle-right" size="inherit" />
              </Link>
            </Box>
          </SpaceBetween>
          ) : null
        }
      </ColumnLayout>
    </Container>
  );
};

export default ChallengeGlobalInfo;
