import * as React from 'react';
import { Grid, Box, Button } from '@amzn/awsui-components-react';
import { RoutePath } from "@/src/RoutePath";
import { Link as ReactLink, useHistory } from "react-router-dom";
import { useFlashbars } from '@/src/store/flashbar.context';
import { errorFlashbar } from '@/src/utils/notification.utils';
import { i18nKeys, withI18NPrefix } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';

interface NoMatchProps {
  path: string;
}

const HomePageButton = () => {
  const { t } = useTranslation();
  return (
    <ReactLink to={RoutePath.ROOT}>
      <Button variant={'primary'}>{t(i18nKeys.nomatch.homeButton)}</Button>
    </ReactLink>
  );
};

const ContentArea = ({ title, code, subtitle, children }: {title: string, code: string, subtitle?: string, children: React.ReactChild}) => (
  <>
    <Box variant="p">{code}</Box>
    <Box variant="h1" fontSize='display-l'>{title}</Box>
    { !!subtitle && <Box variant="h2" fontSize="display-l" fontWeight='light'>{subtitle}</Box> }
    {children}
    <Box margin={{top: 'm'}}>
      <HomePageButton />
    </Box>
  </>
)

const PageSpecificContent = ({path, from} : { path: string, from: string | null}) => {
  const { t } = useTranslation();
  if (path === RoutePath.JAM_404) {
    return (
      <ContentArea
        code={t(i18nKeys.nomatch.code, { code: "404" })}
        title={t(i18nKeys.nomatch.title404)}
        subtitle={t(i18nKeys.nomatch.subtitle404)}>
          <Box variant='h1' fontWeight='normal' tagOverride='p'>
            {t(i18nKeys.nomatch.content404)}
          </Box>
      </ContentArea>
    );
  } else if (path === RoutePath.JAM_403) {
    if (from === "game") {
      return (<ContentArea
        code={t(i18nKeys.nomatch.code, { code: "403" })}
        title={t(i18nKeys.nomatch.title403)}>
            <Box variant='h1' fontWeight='normal' tagOverride='p'>
              {t(i18nKeys.nomatch.content403game)}
            </Box>
        </ContentArea>)
    }
    return (
      <ContentArea
        code={t(i18nKeys.nomatch.code, { code: "403" })}
        title={t(i18nKeys.nomatch.title403)}
        subtitle={t(i18nKeys.nomatch.subtitle403)}>
            <Box variant='h1' fontWeight='normal' tagOverride='p'>
              {t(i18nKeys.nomatch.content403)}
            </Box>
        </ContentArea>
    );
  } else if (path === RoutePath.JAM_500) {
    return (
      <ContentArea
        code={t(i18nKeys.nomatch.code, { code: "500" })}
        title={t(i18nKeys.nomatch.title500)}>
        <Box variant='h1' fontWeight='normal' tagOverride='p'>
          {t(i18nKeys.nomatch.content500)}
        </Box>
      </ContentArea>
    );
  }
  return <></>;
};

const pageSpecificImage = (path: string) => {
  let srcImage = '';
  if (path === RoutePath.JAM_404) {
    srcImage = '/assets/page-404.png';
  } else if (path === RoutePath.JAM_403) {
    srcImage = '/assets/page-403.png';
  } else if (path === RoutePath.JAM_500) {
    srcImage = '/assets/page-500.png';
  }
  return <img src={srcImage} alt="" />;
};

export const NoMatch: React.FC<NoMatchProps> = ({ path }) => {
  const { addFlashbar } = useFlashbars();
  const history = useHistory()
  const queryParams = new URLSearchParams(history.location.search);
  const from = queryParams.get("from");

  React.useEffect(() => {
    if (path === RoutePath.JAM_403) {
      setTimeout(() => {
        addFlashbar(
          errorFlashbar(
            withI18NPrefix(i18nKeys.errors.requestFailed.accessDenied),
            withI18NPrefix(i18nKeys.errors.requestFailed.insufficientPermissions)
          )
        );
      }, 0)
    }
  }, [path])

  return (
    <Box>
      <Grid gridDefinition={[{ colspan: 6 }, { colspan: 4 }]}>
        <Box
          margin={{ top: 'xxxl', left: 'xxxl' }}
          padding={{ top: 'xxxl', left: 'xxxl' }}
          textAlign="left"
          float="right">
          <Box margin={{ top: 'xxxl', right: 'xxxl' }} padding={{ top: 'xxxl', right: 'xxxl' }}>
            <PageSpecificContent path={path} from={from} />
          </Box>
        </Box>
        <Box margin={{ top: 'xxxl' }} padding={{ top: 'xxxl', right: 'xxxl' }} float="left">
          {pageSpecificImage(path)}
        </Box>
      </Grid>
    </Box>
  );
};
