import moment from 'moment-timezone';
import { Moment } from 'moment';
import { DateString, NullableDateString, NullableNumber, NullableString, NullableTimeStamp } from '../types/common';
import { Event, EventBase } from '../types/Event';
import { PopularTimezone } from '../constants/common';

export const YYYY_MM_DD = 'YYYY-MM-DD';
export const YYYY_MM_DD_SLASH = 'YYYY/MM/DD';

/**
 * Takes a timezone and returns a boolean signifying whether or not to use 12 hour formatting
 *
 * @param timezone
 * @returns boolean
 */
export const shouldUse12HourTime = (timezone?: NullableDateString): boolean => {
  if (timezone) {
    return isInUnitedStatesTimezone(getUTCOffsetHour(timezone));
  }
  const moment1 = moment().hour(23);
  return moment1.format('LT').toLowerCase().endsWith('pm');
};

/**
 * Takes a time, the current time, and an optional timezone and returns a dateFormat string
 *
 * @param time
 * @param now
 * @param timezone
 * @returns string
 */
export const getTimeFormat = (
  time: Moment,
  now: Moment,
  formatOptions: {
    includeDate: boolean;
    includeTime: boolean;
    includeComma?: boolean;
  },
  timezone?: string
) => {
  timezone = timezone ? timezone : getBrowserTimezoneName();
  let timeFormat = '';
  let dateFormat = '';
  if (formatOptions.includeDate) {
    dateFormat = time.year() === now.year() ? 'MMM Do' : 'MMM Do YYYY';
  }
  if (formatOptions.includeTime) {
    timeFormat = shouldUse12HourTime(timezone) ? 'h:mm A z' : 'HH:mm z';
  }
  return `${dateFormat}${formatOptions.includeComma ? ', ' : ' '}${timeFormat}`;
};

/**
 * Takes an offset in hours and returns a boolean signifying whether or not it is in the US offset range
 *
 * @param offsetHour Offset in hours
 * @returns boolean signifying that the offset is within the US range
 */
export const isInUnitedStatesTimezone = (offsetHour: number): boolean => {
  // United States offset goes from -4 (east coast) to -10 (hawaii)
  return offsetHour >= -10 && offsetHour <= -4;
};

/**
 * Takes a dateTimeString and parses it for hour in given timezone
 *
 * @param dateTimeString DateTime in string format
 * @param timezone timezone as string
 * @returns number representing hour in date time string
 */
export const getHour = (dateTimeString: NullableDateString, timezone: NullableDateString): NullableNumber => {
  if (!dateTimeString || !timezone) {
    return null;
  }
  const time = moment(dateTimeString).tz(timezone);
  return time.get('hour');
};

/**
 * Takes a dateTimeString and formats it for given timezone
 *
 * @param dateTimeString DateTime in string format
 * @param timezone
 * @returns
 */
export const getTimeInEventLocalTime = (
  dateTimeString: NullableDateString,
  timezone: NullableDateString,
  formatOptions: {
    includeDate: boolean;
    includeTime: boolean;
  }
): NullableString => {
  if (!dateTimeString) {
    return null;
  }

  if (!timezone) {
    timezone = getBrowserTimezoneName() as string;
  }
  const time = moment(dateTimeString).tz(timezone);
  return time.format(getTimeFormat(time, moment.tz(timezone), formatOptions, timezone)).trim();
};

/**
 * Takes a dateTime, a timezone, and a desired number of minutes to add to dateTime
 *
 * @param dateTimeString DateTime in string format
 * @param timezone timezone as string
 * @param minutes number of minutes to add to time
 * @returns new DateTime string with added minutes
 */
export const addMinutesToEventLocalTime = (
  dateTimeString: NullableDateString,
  timezone: NullableDateString,
  minutes: NullableTimeStamp
) => {
  if (!dateTimeString || !timezone) {
    return null;
  }

  const time = moment(dateTimeString).add(minutes, 'minutes').tz(timezone);
  return time.format(getTimeFormat(time, moment.tz(timezone), { includeDate: true, includeTime: true }, timezone));
};

/**
 * Converts given dateTime to browser local time
 *
 * @param dateTimeString DateTime in string format
 * @returns null or a new dateTime string in browser local time
 */
export const getTimeInBrowserLocalTime = (
  dateTimeString: NullableDateString,
  formatOptions: {
    includeDate: boolean;
    includeTime: boolean;
    includeComma?: boolean;
  }
): NullableString => {
  if (!dateTimeString) {
    return null;
  }

  const timezone = getBrowserTimezoneName();
  const time = moment(dateTimeString).tz(timezone as string);
  return time
    .format(
      getTimeFormat(
        time,
        moment(),
        {
          includeDate: formatOptions.includeDate,
          includeTime: formatOptions.includeTime,
          includeComma: formatOptions.includeComma,
        },
        timezone
      )
    )
    .trim();
};

/**
 * Takes in a dateTimeString and parses it for a DateTime compatible timeString
 *
 * @param dateTimeString
 * @returns DateTime compatible time string
 */
export const getTimeForPolarisTimePicker = (dateTimeString: NullableDateString): NullableDateString => {
  if (!dateTimeString) {
    return null;
  }

  const dateString = getTimeInBrowserLocalTime(dateTimeString, { includeDate: false, includeTime: true });
  if (dateString) {
    const time = dateString?.split(' ')[0];
    const hour = time.split(':')[0];
    if (Number(hour) < 10) {
      return '0' + time;
    } else {
      return time;
    }
  }
  return null;
};

/**
 * Takes in a Polaris DatePicker DateString and returns a formatted DateString that is compatible with expected Backend format
 *
 * @param dateTimeString DateTimeString to convert to backend startDate format
 * @returns Backend compatible DateTimeString format
 */
export const polarisDateTimeToMoment = (date: DateString, time: string, timezone: string) => {
  const [year, month, day] = date.split('-');
  const [hour, minute] = moment(time, ['h:mm A']).tz(timezone).format('HH:mm').split(':');

  return moment
    .tz(timezone)
    .year(Number(year))
    .month(Number(month) - 1)
    .date(Number(day))
    .hour(Number(hour))
    .minute(Number(minute))
    .second(0);
};

export const getTimeInUTCTime = (dateTimeString: NullableDateString) => {
  const time = moment(dateTimeString).utc();
  return time.format(getTimeFormat(time, moment(), { includeDate: false, includeTime: true }, undefined)).trim();
};

/**
 * Takes a timezone and returns the number of hours it's offset by
 *
 * @param timezone timezone as string
 * @returns number of hourse timezone is offset by
 */
export const getUTCOffsetHour = (timezone?: string): number => {
  const offsetMinutes = getUTCOffsetMinutes({ timezone });
  return Math.floor(offsetMinutes / 60);
};

/**
 * Takes a dateTime and a timezone optionally and returns the number of minutes it's offset by
 *
 * @param options Object containing dateTime and timezone
 * @returns a the number of minutes and timezone is offset by
 */
export const getUTCOffsetMinutes = (options: { dateTimeString?: string; timezone?: NullableString }): number => {
  const { dateTimeString, timezone } = options;
  let m = moment();
  if (dateTimeString) {
    if (timezone) {
      m = moment(dateTimeString).tz(timezone);
    } else {
      m = moment.parseZone(dateTimeString);
    }
  } else if (timezone) {
    m = moment().tz(timezone);
  }
  return m.utcOffset();
};

/**
 * Takes a dateTime and a timezone and returns the compelete offset
 *
 * @param dateTimeString DateTime as a string
 * @param timezone timezone as a string
 * @returns string depicting complete offset of dateTime
 */
export const getUTCOffset = (dateTimeString?: NullableString, timezone?: NullableString): NullableString => {
  if (!dateTimeString) {
    return null;
  }
  const offsetMinutes = getUTCOffsetMinutes({ dateTimeString, timezone });
  const offsetPlusMinus = offsetMinutes > 0 ? '+' : '-';
  const hours = Math.floor(Math.abs(offsetMinutes) / 60);
  const minutes = Math.abs(offsetMinutes) % 60;
  const hoursStr = hours < 10 ? `0${hours}` : `${hours}`;
  const minsStr = minutes < 10 ? `0${minutes}` : `${minutes}`;
  return `${offsetPlusMinus}${hoursStr}:${minsStr}`;
};

/**
 * Returns the offset of the browser timezone
 *
 * @returns browser offset
 */
export const getBrowserUTCOffset = (): string => {
  return moment().format('Z');
};

/**
 * Finds the timezone that has the same offset as the browser offset and returns the name
 *
 * @returns name of the browser timezone
 */
export const getBrowserTimezoneName = () => {
  const browserUtcOffset = getBrowserUTCOffset();

  // search through all timezones to find the first timezone matching the same utc offset
  // the most popular timezones are hard coded first in the list to avoid choosing an
  // obscure timezone in place of a more popular one.

  return [...(Object.values(PopularTimezone) as string[]), ...moment.tz.names()]
    .filter((name) => name.length > 3 && !name.startsWith('Etc'))
    .find((tzName: string) => {
      return browserUtcOffset === moment.tz(tzName).format('Z');
    });
};

/**
 * Takes an event and checks it's startDate timezone against browser timezone to see if they're the same
 *
 * @param event Event containing startDate to compare against browser timezone
 * @returns boolean indicating whether eventStartDate is the same timezone as browser
 */
export const isEventInSameTimezoneAsBrowser = (event: Event | EventBase): boolean => {
  if (event?.startDate) {
    return isInSameTimezoneAsBrowser(event.startDate);
  } else {
    return false;
  }
};

/**
 * Checks given dateTime offset to determine if it's in the same timezone as browser
 *
 * @param datetime DateTime as a string
 * @returns boolean indicating whether given dateTime is in the same timezone as browser
 */
export const isInSameTimezoneAsBrowser = (datetime: string): boolean => {
  const eventOffset = getUTCOffset(datetime);
  return eventOffset === getBrowserUTCOffset();
};

/**
 *
 * @param millis milliseconds to convert to a Days/Hours/Minutes string
 * @param includeSeconds boolean determining whether to inclulde seconds in returned string
 * @returns string depicting Days, hours, and minutes until specified millis time
 */
export const getDaysHoursMinutes = (millis: NullableNumber, includeSeconds = true) => {
  if (millis == null) {
    return '--';
  }

  if (millis < 60000) {
    includeSeconds = true;
  }

  let t = Math.floor(millis / 1000);

  const days = Math.floor(t / 86400);
  t -= days * 86400;
  const hours = Math.floor(t / 3600) % 24;
  t -= hours * 3600;
  const minutes = Math.floor(t / 60) % 60;
  t -= minutes * 60;
  const seconds = t % 60;

  const showDays = days > 0;
  const showHours = (showDays || hours > 0) && days < 1;
  const showMinutes = (showHours || minutes > 0) && days < 1;

  return [
    showDays ? `${days}d` : '',
    showHours ? `${hours}h` : '',
    showMinutes ? `${minutes}m` : '',
    includeSeconds ? `${seconds}s` : '',
  ]
    .join(' ')
    .trim();
};

/**
 * Maps over moment's timezones and adds their respective timezone abbreviations and UTC offsets to label
 *
 * @returns A List of timezones with their respective UTC offsets and abbreviations
 */
export const getTimezonesWithUTCOffsetAsOptionList = () => {
  return moment.tz.names().map((timezone) => {
    const utc: string = moment.tz(timezone).format('Z');
    let abbr = moment.tz(timezone).zoneAbbr();
    if (!abbr || timezone.includes(abbr) || /\d/.test(abbr) === true) {
      abbr = '';
    } else {
      abbr = ` (${abbr})`;
    }
    return {
      label: `${timezone}${abbr}, UTC${utc}`,
      value: timezone,
    };
  });
};

/**
 * Parses a string in dd:hh format and returns total number of hours
 * Returns null if there was an issue parsing
 */
export const parseDayHourToHours = (duration: string) => {
  const time = duration.split(':');
  if (time.length > 2) {
    return null;
  }
  const dayInHrs = Number(time[0]);
  const hrs = Number(time[1]);

  if (!dayInHrs || !hrs) return null;

  return dayInHrs * 24 + hrs;
};

/**
 * Parses a string in 24 format and returns 12 hours time with meridian
 */
export const convert24To12HourTime = (time: string, withMeridian: boolean): string => {
  const [hourString, minute] = time.split(':');
  const hour = +hourString % 24;
  let newMinuteString = minute;
  const minutes = minute.split(' ');
  const newHour = hour % 12 || 12;
  const newHourString = newHour < 10 ? `0${newHour}` : newHour;
  if (minutes.length > 0) {
    const newMinute = +minutes[0];
    newMinuteString = newMinute < 10 ? `0${newMinute}` : `${newMinute}`;
  }
  const newHourMinutesString = `${newHourString}:${newMinuteString}`;
  if (!withMeridian) {
    return newHourMinutesString;
  }
  return `${newHourMinutesString}${hour < 12 ? 'AM' : 'PM'}`;
};
