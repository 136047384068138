import { i18nKeys } from '@/src/utils/i18n.utils';

export const BOOK_EVENT_STEPS = [
  {
    image: '/assets/monkey-search.png',
    title: i18nKeys.catalog.bookEventSteps.steps.step1.title,
    background: 'step__item__image__background1',
    imageClass: 'step__item__image__avatar1',
    link: i18nKeys.catalog.bookEventSteps.steps.step1.link,
    subtitle: i18nKeys.catalog.bookEventSteps.steps.step1.subtitle,
  },
  {
    image: '/assets/book-event-step2.png',
    title: i18nKeys.catalog.bookEventSteps.steps.step2.title,
    background: 'step__item__image__background2',
    imageClass: 'step__item__image__avatar2',
    subtitle: i18nKeys.catalog.bookEventSteps.steps.step2.subtitle,
  },
  {
    image: '/assets/book-event-step3.png',
    title: i18nKeys.catalog.bookEventSteps.steps.step3.title,
    background: 'step__item__image__background3',
    imageClass: 'step__item__image__avatar3',
    subtitle: i18nKeys.catalog.bookEventSteps.steps.step3.subtitle,
  },
];
