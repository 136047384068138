import React, { useState, useContext, useRef, useEffect, Dispatch, SetStateAction } from 'react';
import { JamTeamChat } from '../types/JamTeam';
import { JamSupportChat } from '../types/JamSupportChat';
import { useApi } from './api.context';
import { ChatMessage, ChatType } from '../types/Chat';
import { useJamEventDetails } from './jam-event-details.context';
import { noop } from 'lodash';
import localStorageTTL from '../utils/localStorageTTL.utils';
import { getSupportChatCounterKey, getTeamChatCounterKey } from '../utils/chat.utils';

export interface JamChatValue {
  teamChat: JamTeamChat | null;
  supportChats: JamSupportChat[];
  allSupportChats: JamSupportChat[];
  loadTeamChat: (eventName: string) => Promise<void>;
  loadSupportChats: (eventName: string) => Promise<void>;
  loadAllSupportChats: (eventName: string) => Promise<void>;
  updateTeamChatMessages: (messages: ChatMessage[]) => void;
  updateSupportChatMessages: (activeChat: JamSupportChat) => void;
  activeChatType?: ChatType;
  setActiveChatType: (type?: ChatType) => void;
  unreadTeamChatMessage: number;
  selectedSupportChatId?: string;
  setSelectedSupportChatId: (id: string | undefined) => void;
  viewAllSupportChats: boolean;
  setViewAllSupportChats: Dispatch<SetStateAction<boolean>>;
  unreadSupportChatCounter: { [key: string]: number };
  updateSupportChatReadCounter: (chatId: string, count: number) => void;
}

const JamChatContext = React.createContext<JamChatValue>({
  teamChat: null,
  supportChats: [],
  allSupportChats: [],
  loadTeamChat: () => new Promise(noop),
  loadSupportChats: () => new Promise(noop),
  loadAllSupportChats: () => new Promise(noop),
  updateTeamChatMessages: noop,
  updateSupportChatMessages: noop,
  activeChatType: undefined,
  setActiveChatType: noop,
  unreadTeamChatMessage: 0,
  selectedSupportChatId: undefined,
  setSelectedSupportChatId: noop,
  viewAllSupportChats: false,
  setViewAllSupportChats: noop,
  unreadSupportChatCounter: {},
  updateSupportChatReadCounter: noop,
});

const JamChatProvider: React.FC = ({ children }) => {
  const { event, eventName: eventId } = useJamEventDetails();

  const [teamChat, setTeamChat] = useState<JamTeamChat | null>(null);
  const [supportChats, setSupportChats] = useState<JamSupportChat[]>([]);
  const [allSupportChats, setAllSupportChats] = useState<JamSupportChat[]>([]);
  const [activeChatType, setActiveChatType] = useState<ChatType | undefined>();
  const [unreadTeamChatMessage, setUnreadTeamChatMessage] = useState(0);
  const [unreadSupportChatCounter, setUnreadSupportChatCounter] = useState<{ [key: string]: number }>({});
  const [selectedSupportChatId, setSelectedSupportChatId] = useState<string | undefined>();
  const [viewAllSupportChats, setViewAllSupportChats] = useState(false);
  // we need this variable because
  // state is not updating due to
  // updateTeamChatMessages and updateSupportChatMessages
  // are getting wrapped in useEffect in websocket context
  // hence activeChatType will always be initial value in3
  // those method
  const activeChatTypeStatic = useRef<ChatType | undefined>();
  const selectedSupportChatIdStatic = useRef<string | undefined>();
  const viewAllSupportChatsStatic = useRef<boolean>(false);

  selectedSupportChatIdStatic.current = selectedSupportChatId;
  viewAllSupportChatsStatic.current = viewAllSupportChats;
  activeChatTypeStatic.current = activeChatType;

  const { jamMessageApi, jamSupportChatApi } = useApi();

  const loadTeamChat = async (eventName: string) => {
    try {
      const chat = await jamMessageApi.getTeamChat({ eventName });
      setTeamChat(chat);
    } catch (e) {
      // API will handle the error
      // if not reset it will load existing message in different event as well
      setTeamChat(null);
    }
  };

  const loadSupportChats = async (eventName: string) => {
    try {
      const chats = await jamSupportChatApi.getActiveSupportChats(eventName);
      setSupportChats(chats);
    } catch (e) {
      // API will handle the error
    }
  };

  const loadAllSupportChats = async (eventName: string) => {
    try {
      const chats = await jamSupportChatApi.getAllSupportChats(eventName);
      setAllSupportChats(chats);
    } catch (e) {
      // API will handle the error
    }
  };

  const updateTeamChatMessages = (chatMessages: ChatMessage[]) => {
    if (activeChatTypeStatic.current === 'team') {
      setUnreadTeamChatMessage(0);
    } else {
      setUnreadTeamChatMessage((prev) => prev + chatMessages.length);
    }

    setTeamChat((prev) => {
      return {
        ...(prev as JamTeamChat),
        chatMessages: [...(prev?.chatMessages || []), ...chatMessages],
      };
    });
  };

  const updateSupportChatMessages = (supportChat: JamSupportChat) => {
    const updateChats = (prev: JamSupportChat[]) => {
      if (!prev.length) return [supportChat];
      if (prev.findIndex((chat) => chat.id === supportChat.id) >= 0) {
        return prev.map((activeChat) => (activeChat.id === supportChat.id ? supportChat : activeChat));
      }
      return [...prev, supportChat];
    };

    if (!supportChat.assignedToDisplayName && event?.facilitator) {
      setAllSupportChats((prev) => (!prev.length ? [supportChat] : [...prev, supportChat]));
      return;
    } else if (supportChat.assignedToDisplayName && event?.facilitator) {
      setAllSupportChats(updateChats);
    }
    setSupportChats(updateChats);

    // support chat counter logic
    if (activeChatTypeStatic.current !== 'support' || (event?.facilitator && !viewAllSupportChatsStatic.current)) {
      setUnreadSupportChatCounter((prev) => ({ ...prev, [supportChat.id]: (prev[supportChat.id] || 0) + 1 }));
    } else {
      setUnreadSupportChatCounter((prev) => ({ ...prev, [supportChat.id]: 0 }));
    }
  };

  const updateSupportChatReadCounter = (chatId: string, count: number) => {
    setUnreadSupportChatCounter((prev) => ({ ...prev, [chatId]: count }));
  };

  useEffect(() => {
    if (activeChatType === 'team') {
      setUnreadTeamChatMessage(0);
    }
  }, [activeChatType]);

  useEffect(() => {
    if (!eventId) {
      return;
    }
    localStorageTTL.setItem(getTeamChatCounterKey(eventId), unreadTeamChatMessage.toString(), 7, 'days');
    localStorageTTL.setItem(getSupportChatCounterKey(eventId), JSON.stringify(unreadSupportChatCounter), 7, 'days');
  }, [unreadSupportChatCounter, unreadTeamChatMessage]);

  useEffect(() => {
    if (!eventId) {
      return;
    }
    const initialTeamChatCounter = parseInt(
      (localStorageTTL.getItem(getTeamChatCounterKey(eventId)) as string) || '0',
      10
    );
    const initialSupportChatCounter = JSON.parse(
      (localStorageTTL.getItem(getSupportChatCounterKey(eventId)) as string) || '{}'
    ) as {
      [key: string]: number;
    };
    setUnreadTeamChatMessage(initialTeamChatCounter);
    setUnreadSupportChatCounter(initialSupportChatCounter);
  }, [eventId]);

  const data: JamChatValue = {
    teamChat,
    supportChats,
    allSupportChats,
    activeChatType,
    unreadTeamChatMessage,
    selectedSupportChatId,
    setSelectedSupportChatId,
    setActiveChatType,
    loadTeamChat,
    loadSupportChats,
    loadAllSupportChats,
    updateTeamChatMessages,
    updateSupportChatMessages,
    viewAllSupportChats,
    setViewAllSupportChats,
    unreadSupportChatCounter,
    updateSupportChatReadCounter,
  };

  return <JamChatContext.Provider value={data}>{children}</JamChatContext.Provider>;
};

const useJamChat = () => {
  const context = useContext(JamChatContext);
  if (context === undefined) {
    throw new Error('useJamChat can only be used inside JamChatProvider');
  }
  return context;
};

export { JamChatProvider, useJamChat };
