const AWSServiceList = [
  'Alexa for Business',
  'Amazon API Gateway',
  'Amazon AppStream 2.0',
  'Amazon Athena',
  'Amazon Aurora - MySQL-compatible',
  'Amazon Aurora - PostgreSQL-compatible',
  'Amazon Aurora',
  'Amazon Chime',
  'Amazon Cloud Directory',
  'Amazon CloudSearch',
  'Amazon CloudWatch',
  'Amazon CloudWatch Events',
  'Amazon CloudWatch Logs',
  'Amazon Cognito',
  'Amazon Comprehend',
  'Amazon Connect',
  'Amazon DeepLens',
  'Amazon DynamoDB',
  'Amazon DynamoDB Accelerator (DAX)',
  'Amazon Elastic Container Registry (ECR)',
  'Amazon Elastic Container Service (ECS)',
  'Amazon Elastic Container Service for Kubernetes (EKS)',
  'Amazon ElastiCache',
  'Amazon Elastic Block Store (EBS)',
  'Amazon Elastic Compute Cloud (EC2)',
  'Amazon Elastic Compute Cloud (EC2) Auto Scaling',
  'Amazon Elastic File System (EFS)',
  'Amazon Elastic MapReduce (EMR)',
  'Amazon Elasticsearch Service',
  'Amazon Elastic Transcoder',
  'Amazon Fargate',
  'Amazon FreeRTOS',
  'Amazon GameLift',
  'Amazon Glacier',
  'Amazon GuardDuty',
  'Amazon Inspector',
  'Amazon Kinesis Data Analytics',
  'Amazon Kinesis Data Firehose',
  'Amazon Kinesis Data Streams',
  'Amazon Kinesis Video Streams',
  'Amazon Lex',
  'Amazon Lightsail',
  'Amazon Machine Learning',
  'Amazon Macie',
  'Amazon Mobile Analytics',
  'Amazon MQ',
  'Amazon Neptune',
  'Amazon Pinpoint',
  'Amazon Polly',
  'Amazon QuickSight',
  'Amazon Redshift',
  'Amazon Rekognition Image',
  'Amazon Rekognition Video',
  'Amazon Relational Database Service (RDS)',
  'Amazon Route 53 Auto Naming',
  'Amazon Sagemaker',
  'Amazon SimpleDB',
  'Amazon Simple Email Service (SES)',
  'Amazon Simple Notification Service (SNS)',
  'Amazon Simple Queue Service (SQS)',
  'Amazon Simple Storage Service (S3)',
  'Amazon Simple Workflow Service (SWF)',
  'Amazon Transcribe',
  'Amazon Translate',
  'Amazon Virtual Private Cloud (VPC)',
  'Amazon WorkDocs',
  'Amazon WorkMail',
  'Amazon WorkSpaces',
  'Amazon WorkSpaces Application Manager',
  'AWS Auto Scaling',
  'AWS Application Discovery Service',
  'AWS Application Migration Service',
  'AWS AppSync',
  'AWS Batch',
  'AWS Certificate Manager',
  'AWS CloudFormation',
  'AWS Cloud9',
  'AWS CloudHSM',
  'AWS CloudHSM Classic',
  'AWS CloudTrail',
  'AWS CodeBuild',
  'AWS CodeCommit',
  'AWS CodeDeploy',
  'AWS CodePipeline',
  'AWS CodeStar',
  'AWS Config',
  'AWS Database Migration Service',
  'AWS Data Pipeline',
  'AWS DataSync',
  'AWS Device Farm',
  'AWS Direct Connect',
  'AWS Directory Service',
  'AWS Elastic Beanstalk',
  'AWS Elemental MediaConvert',
  'AWS Elemental MediaLive',
  'AWS Elemental MediaPackage',
  'AWS Elemental MediaStore',
  'AWS Elemental MediaTailor',
  'AWS Glue',
  'AWS Greengrass',
  'AWS IoT',
  'AWS IoT Core',
  'AWS IoT 1-Click',
  'AWS IoT Analytics',
  'AWS IoT Device Management',
  'AWS Key Management Service',
  'AWS Lambda',
  'AWS License Manager',
  'AWS Managed Services',
  'AWS Marketplace',
  'AWS Migration Hub',
  'AWS Mobile Hub',
  'AWS OpsWorks Stacks',
  'AWS OpsWorks for Chef Automate',
  'AWS OpsWorks for Puppet Enterprise',
  'AWS Personal Health Dashboard',
  'AWS Secrets Manager',
  'AWS Server Migration Service',
  'AWS Service Catalog',
  'AWS Shield Standard',
  'AWS Shield Advanced',
  'AWS Single Sign-On',
  'AWS Snowball',
  'AWS Snowball Edge',
  'AWS Snowmobile',
  'AWS Step Functions',
  'AWS Storage Gateway',
  'AWS Sumerian',
  'AWS Support',
  'AWS Systems Manager (SSM)',
  'AWS Trusted Advisor',
  'AWS WAF',
  'AWS X-Ray',
  'Elastic Load Balancing',
  'VM Import/Export',
  'AWS Identity and Access Management (IAM)',
  'Amazon CloudFront',
  'Amazon Route 53',
  'AWS Shield',
  'AWS Security Hub',
  'AWS Mobile Hub',
  'AWS Organizations',
];

const shortName = (service: string): string => {
  if (/.*\(\w{2,3}\)/.test(service)) {
    return service.split(/\(/g)
      .reverse()[0]
      .split(')')[0];
  }
  if (service.startsWith('Amazon ')) {
    return service.replace('Amazon ', '');
  }
  if (service.startsWith('AWS ')) {
    return service.replace('AWS ', '');
  }
  return service;
};

// noinspection JSUnusedGlobalSymbols
export const AwsServices = {
  list: AWSServiceList,
  shortName,
};
