/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect } from 'react';
import { Box, Button, RadioGroup, SpaceBetween } from '@amzn/awsui-components-react';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import LearnSomethingAndFeedback from './LearnSomethingAndFeedback';
import RatingContainer from './RatingContainer';
import { JamChallengeFeedback } from '@/src/types/JamFeedback';
import { useJamFeedback } from '@/src/store/jam-feedback';
import { useJamChallenge } from '@/src/store/jam-challenge.context';
import { useFlashbars } from '@/src/store/flashbar.context';
import { IEventFeedbackData } from '@/src/types/JamCommon';

interface IFeedbackRes {
  challengeId?: any;
}

const ChallengeFeedback: React.FC<IFeedbackRes> = ({ challengeId }) => {
  const { t } = useTranslation();
  const { loadJamChallengeFeedbackData } = useJamFeedback();
  const { eventFeedbackData } = useJamChallenge();
  const { clearFlashbars } = useFlashbars();
  const [challengeFeedback, setChallengeFeedback] = React.useState<JamChallengeFeedback>({
    didYouLearnSomethingNew: '',
    challengeDifficulty: 0,
    challengeRank: 0,
    notes: '',
    updatedDate: '',
  });

  useEffect(() => {
    if ((eventFeedbackData as unknown as IEventFeedbackData)?.challengeFeedback) {
      const feedback = (eventFeedbackData as unknown as IEventFeedbackData).challengeFeedback;

      const rank: JamChallengeFeedback = feedback[challengeId];
      if (rank) {
        const obj = {
          didYouLearnSomethingNew:
            rank.didYouLearnSomethingNew === true ? 'yes' : rank.didYouLearnSomethingNew === false ? 'no' : '',
          challengeDifficulty: rank.challengeDifficulty || 0,
          challengeRank: rank.challengeRank,
          notes: rank?.notes || '',
          updatedDate: rank.updatedDate || '',
        };
        setChallengeFeedback(obj);
      }
    }
  }, []);

  const handleChallengeFeedback = (name: string, value: number | number) => {
    setChallengeFeedback({ ...challengeFeedback, [name]: value });
  };

  const handleChallengeRadioAndComment = (name: string, value: string) => {
    setChallengeFeedback({ ...challengeFeedback, [name]: value });
  };

  const handleSubmit = () => {
    clearFlashbars();
    void loadJamChallengeFeedbackData({
      challengeId,
      body: {
        ...challengeFeedback,
        didYouLearnSomethingNew: challengeFeedback.didYouLearnSomethingNew === 'yes' ? true : false,
        challengeDifficulty: Number(challengeFeedback.challengeDifficulty),
        updatedDate: new Date().toISOString(),
      },
    });
  };

  return (
    <SpaceBetween direction="vertical" size="m">
      <RatingContainer
        label={t(i18nKeys.myJams.feedback.label.rating.quality)}
        name="challengeRank"
        rating={challengeFeedback.challengeRank}
        setRating={handleChallengeFeedback}
      />
      <Box variant="div">
        <Box variant="h4">{t(i18nKeys.myJams.feedback.radio.levelOfDifficulty)}</Box>
        <RadioGroup
          onChange={({ detail }) =>
            setChallengeFeedback({ ...challengeFeedback, challengeDifficulty: Number(detail.value) })
          }
          value={String(challengeFeedback.challengeDifficulty)}
          items={[
            { value: '1', label: `1 - ${t(i18nKeys.myJams.feedback.label.difficulty.veryEasy)}` },
            { value: '2', label: `2 - ${t(i18nKeys.myJams.feedback.label.difficulty.easy)}` },
            { value: '3', label: `3 - ${t(i18nKeys.myJams.feedback.label.difficulty.moderate)}` },
            { value: '4', label: `4 - ${t(i18nKeys.myJams.feedback.label.difficulty.difficult)}` },
            { value: '5', label: `5 - ${t(i18nKeys.myJams.feedback.label.difficulty.veryDifficult)}` },
          ]}
        />
      </Box>
      <LearnSomethingAndFeedback
        radio={challengeFeedback.didYouLearnSomethingNew}
        textArea={challengeFeedback.notes}
        radioName="didYouLearnSomethingNew"
        textAreaName="notes"
        setValue={handleChallengeRadioAndComment}
      />
      <Box float="right">
        <Button variant="primary" onClick={handleSubmit}>
          {t(i18nKeys.myJams.feedback.buttons.submitFeedback)}
        </Button>
      </Box>
    </SpaceBetween>
  );
};

export default ChallengeFeedback;
