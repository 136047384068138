// constants
import { INavSubMenuItemWithAuthRoles, NAV_MENU } from "@/src/constants/navMenu"

// types
import type { INavSubMenuItem, INavMenuItem } from "@/src/components/ui/organisms/Header/Header.types"
import type { User } from "@/src/types/User"
import { AuthRole } from "../constants/shared"
import { RoutePath } from "../RoutePath"


const getNavSubMenuForUser = (user: User | null, subMenuItems?: INavSubMenuItemWithAuthRoles[]):INavSubMenuItem[] => {
  if (!subMenuItems) return []
  return subMenuItems
    .filter((item) => item.roles.some((role) => {
      if (!user) {
        return role === AuthRole.GUEST_USER
      }
      return user.memberOfGroup(role)
    })) // remove submenuitems for which user doesn't have access
    .map((item) => ({ ...item, roles: undefined })) // remove roles since its not needed
}

export const getNavMenuForUser = (user: User | null):INavMenuItem[] => {
  return NAV_MENU
    .map((item) => ({...item, subMenuItems: getNavSubMenuForUser(user, item.subMenuItems)}))
    .filter((item) => {
      if (item.hasSubMenu) {
        return item.subMenuItems.length > 0
      }
      if(!user && item.href === RoutePath.ADMIN){
        return false;
      }
      if(user && !(user.memberOfGroup(AuthRole.JAM_ADMIN) || user.memberOfGroup(AuthRole.EVENT_SUPPORT)) && item.href === RoutePath.ADMIN) {
        return false;
      }
      else{
        return true;
      }
     }) // remove empty submenuitems
}
