import React from 'react';
import { Box, ColumnLayout, SpaceBetween } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { getDisplayDuration } from '@/src/utils/time.utils';
import { TeamMetricsRow } from '@/src/types/DetailedEventStatisticsReport';

interface TeamOverviewProps {
  teamMetrics: TeamMetricsRow;
}

const TeamOverview: React.FC<TeamOverviewProps> = ({ teamMetrics }) => {
  const { t } = useTranslation();
  return (
    <ColumnLayout columns={4} variant="text-grid">
      <SpaceBetween direction="vertical" size="xxs">
        <Box variant="h5">{t(i18nKeys.report.headers.teamDetails.rank)}</Box>
        <Box>{teamMetrics.finalRank}</Box>
      </SpaceBetween>
      <SpaceBetween direction="vertical" size="xxs">
        <Box variant="h5">{t(i18nKeys.report.headers.teamDetails.totalScore)}</Box>
        <Box>{teamMetrics.finalScore}</Box>
      </SpaceBetween>
      <SpaceBetween direction="vertical" size="xxs">
        <Box variant="h5">{t(i18nKeys.report.headers.teamDetails.challengesSolved)}</Box>
        <Box>{teamMetrics.numberChallengesSolved}</Box>
      </SpaceBetween>
      <SpaceBetween direction="vertical" size="xxs">
        <Box variant="h5">{t(i18nKeys.report.headers.teamDetails.totalTime)}</Box>
        <Box>
          {teamMetrics.timeFromFirstStartedChallengeToLastCompletionTime
            ? getDisplayDuration(teamMetrics.timeFromFirstStartedChallengeToLastCompletionTime) ?? ''
            : '--'}
        </Box>
      </SpaceBetween>
      <SpaceBetween direction="vertical" size="xxs">
        <Box variant="h5">{t(i18nKeys.report.headers.teamDetails.numberOfRestarts)}</Box>
        <Box>{teamMetrics.numberOfRestartsUsed}</Box>
      </SpaceBetween>
      <SpaceBetween direction="vertical" size="xxs">
        <Box variant="h5">{t(i18nKeys.report.headers.teamDetails.awsAccounts)}</Box>
        <Box>{teamMetrics.numberOfAWSAccountsUsed}</Box>
      </SpaceBetween>
      <SpaceBetween direction="vertical" size="xxs">
        <Box variant="h5">{t(i18nKeys.report.headers.teamDetails.supportChats)}</Box>
        <Box>{teamMetrics.numberOfSupportChatsRequested}</Box>
      </SpaceBetween>
      <SpaceBetween direction="vertical" size="xxs">
        <Box variant="h5">{t(i18nKeys.report.headers.teamDetails.suspectedCheater)}</Box>
        <Box>{teamMetrics.suspectedOfCheating ? t(i18nKeys.general.yes) : t(i18nKeys.general.no)}</Box>
      </SpaceBetween>
    </ColumnLayout>
  );
};

export default TeamOverview;
