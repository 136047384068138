/* eslint-disable */
import { NormalizedPath, RouteType } from "./types";

const param = "{param}";

export class UniversalRoute {
  private static readonly SLUG_PATTERN = "[^/]+";
  private static readonly ROUTE_REGEX = new RegExp(
    `^(|/|((/${UniversalRoute.SLUG_PATTERN})+))$`
  );

  private readonly matchesExp: RegExp;

  public constructor(
    private readonly path: string, 
    public readonly routeType: RouteType, 
    public readonly disableRedirects: boolean = false
  ) {
    if (!UniversalRoute.ROUTE_REGEX.test(path)) {
      throw new Error(`Path '${path}' is not a valid route path.`);
    }

    this.matchesExp = new RegExp(`^(${this.path}|${this.path.replace(new RegExp(param, "g"), `(${UniversalRoute.SLUG_PATTERN})`)})$`);
  }

  public matches(path: NormalizedPath): boolean {
    return this.matchesExp.test(path.asString());
  }
}

/**
 * Definitive list of routes across both consoles.
 *
 * For the hybrid console to work, both consoles must use the exact same route for equivalent pages.
 * See [Achieving Route Parity](https://quip-amazon.com/QL6xALPBtj2D/Jam-Console-UI-Side-by-side-Design#temp:C:QcG69aded7ce1574916af47aca20).
 */
const ConsoleRoutes: UniversalRoute[] = [
  new UniversalRoute("", RouteType.PolarisOnly),
  new UniversalRoute("/", RouteType.PolarisOnly),
  /* 
    Disable redirects on the /oauth2 route, since users need to complete authentication while on this route before being redirected.
    Both apps redirect the user to another page after authentication has completed on this route, and the hybrid console redirect
    would interfere with this process.
   */
  new UniversalRoute("/oauth2", RouteType.Both, /* disableRedirects */ true),
  new UniversalRoute("/signout", RouteType.Both),
  new UniversalRoute("/events", RouteType.PolarisOnly),
  new UniversalRoute("/my-events", RouteType.Both),
  new UniversalRoute("/events/new", RouteType.Both),
  new UniversalRoute(`/events/${param}`, RouteType.Both),
  new UniversalRoute(`/events/${param}/challenges`, RouteType.Both),
  new UniversalRoute(`/events/${param}/settings`, RouteType.Both),
  new UniversalRoute(`/events/${param}/comments`, RouteType.Both),
  new UniversalRoute(`/events/${param}/participants`, RouteType.Both),
  new UniversalRoute(`/events/${param}/labs`, RouteType.LegacyOnly),
  new UniversalRoute(`/events/${param}/feedback`, RouteType.Both),
  new UniversalRoute(`/events/${param}/revisions`, RouteType.Both),
  new UniversalRoute(`/events/${param}/report`, RouteType.Both),
  new UniversalRoute("/campaigns", RouteType.LegacyOnly),
  new UniversalRoute(`/campaigns/${param}`, RouteType.LegacyOnly),
  new UniversalRoute(`/campaigns/${param}/challenges`, RouteType.LegacyOnly),
  new UniversalRoute(`/campaigns/${param}/settings`, RouteType.LegacyOnly),
  new UniversalRoute(`/campaigns/${param}/advanced-settings`, RouteType.LegacyOnly),
  new UniversalRoute(`/campaigns/${param}/groups/new`, RouteType.LegacyOnly),
  new UniversalRoute(`/campaigns/${param}/groups/${param}`, RouteType.LegacyOnly),
  new UniversalRoute(`/campaigns/${param}/groups`, RouteType.LegacyOnly),
  new UniversalRoute(`/campaigns/${param}/comments`, RouteType.LegacyOnly),
  new UniversalRoute("/challenges", RouteType.Both),
  new UniversalRoute("/challenges/snippets", RouteType.LegacyOnly),
  new UniversalRoute(`/challenges/snippets/${param}`, RouteType.LegacyOnly),
  new UniversalRoute("/challenges/boards", RouteType.LegacyOnly),
  new UniversalRoute(`/challenges/boards/${param}`, RouteType.LegacyOnly),
  new UniversalRoute("/challenge-set", RouteType.LegacyOnly),
  new UniversalRoute(`/challenge-set/${param}`, RouteType.LegacyOnly),
  new UniversalRoute(`/challenges/${param}`, RouteType.Both),
  new UniversalRoute("/challenges/new", RouteType.Both),
  new UniversalRoute(`/challenges/${param}/learning-outcome`, RouteType.Both),
  new UniversalRoute(`/challenges/${param}/next-steps`, RouteType.LegacyOnly),
  new UniversalRoute(`/challenges/${param}/tasks`, RouteType.LegacyOnly),
  new UniversalRoute(`/challenges/${param}/tasks/${param}`, RouteType.LegacyOnly),
  new UniversalRoute(`/challenges/${param}/testing`, RouteType.LegacyOnly),
  new UniversalRoute(`/challenges/${param}/collaborators`, RouteType.LegacyOnly),
  new UniversalRoute(`/challenges/${param}/settings`, RouteType.Both),
  new UniversalRoute(`/challenges/${param}/wiki`, RouteType.LegacyOnly),
  new UniversalRoute(`/challenges/${param}/facilitator-notes`, RouteType.LegacyOnly),
  new UniversalRoute(`/challenges/${param}/comments`, RouteType.LegacyOnly),
  new UniversalRoute(`/challenges/${param}/issues`, RouteType.LegacyOnly),
  new UniversalRoute(`/challenges/${param}/issues/${param}`, RouteType.LegacyOnly),
  new UniversalRoute(`/challenges/${param}/new-issue`, RouteType.LegacyOnly),
  new UniversalRoute(`/challenges/${param}/feedback`, RouteType.LegacyOnly),
  new UniversalRoute(`/challenges/${param}/assets`, RouteType.LegacyOnly),
  new UniversalRoute(`/challenges/${param}/iam`, RouteType.LegacyOnly),
  new UniversalRoute(`/challenges/${param}/cfn`, RouteType.LegacyOnly),
  new UniversalRoute(`/challenges/${param}/stats`, RouteType.LegacyOnly),
  new UniversalRoute(`/challenges/${param}/revisions`, RouteType.LegacyOnly),
  new UniversalRoute(`/challenges/${param}/translation`, RouteType.LegacyOnly),
  new UniversalRoute(`/challenges/${param}/export`, RouteType.LegacyOnly),
  new UniversalRoute(`/challenges/${param}/help`, RouteType.LegacyOnly),
  new UniversalRoute("/challenge-wiki-example", RouteType.LegacyOnly),
  new UniversalRoute("/reports", RouteType.LegacyOnly),
  new UniversalRoute("/review-challenges", RouteType.PolarisOnly),
  new UniversalRoute("/settings", RouteType.LegacyOnly),
  new UniversalRoute("/labs", RouteType.LegacyOnly),
  new UniversalRoute("/labs/pools", RouteType.LegacyOnly),
  new UniversalRoute("/labs/shutoff", RouteType.LegacyOnly),
  new UniversalRoute("/prizes", RouteType.LegacyOnly),
  new UniversalRoute("/documentation", RouteType.LegacyOnly),
  new UniversalRoute("/account", RouteType.LegacyOnly),
  new UniversalRoute("/ui-samples", RouteType.LegacyOnly),
  new UniversalRoute("/usage-plans", RouteType.PolarisOnly),
  new UniversalRoute("/usage-plans/create", RouteType.PolarisOnly),
  new UniversalRoute(`/usage-plans/${param}`, RouteType.PolarisOnly),
];

/**
 * Get the universal route corresponding to the given path.
 */
export function getUniversalRoute(path: NormalizedPath): UniversalRoute | undefined {
  for (const route of ConsoleRoutes) {
    if (route.matches(path)) {
      return route;
    }
  }

  return undefined;
}
